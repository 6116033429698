import React, { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useTable } from "react-table";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { Order } from "../types/types";
import { patchOrder } from "../utils/ordersApi";
import axios from "axios";
import {
  configDataConstants,
  ordersConstants,
  tableInfoConstants,
  tableTypeConstants,
} from "../store/actions/types";
import { ConfData3 as workstation } from "../const/const";
import { ConfData3 as meeting } from "../const/meetingTables";
import { ConfData3 as onetwentydegree } from "../const/120Degree";
import { ConfData3 as plansystem } from "../const/planSystemLinear";
import { ConfData3 as hat } from "../const/heightAdjustableTables";
import { ConfData3 as cCluster } from "../const/cCluster";
import { ConfData3 as discussion } from "../const/discussionTables";
import { ConfData3 as cabinTable } from "../const/cabinTablesNew";
import { ConfData3 as neoWorkstation } from "../const/neoWorkstations";
import editIcon from "../assets/icons/edit.svg";
import deleteIcon from "../assets/icons/delete.svg";
import { makeSessionRequest } from "../utils/makeSessionRequest";
import BomTable from "./BOMTable";

function Table({ columns, data, order }: any) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const dispatch = useAppDispatch();
  const history = useHistory();
  const pricingApi = process.env.REACT_APP_HELLOAR_PRICING;

  const tableType = useAppSelector((state) => state.tableType.table);

  const userConfiguration2 = useAppSelector(
    (state) => state.configuration.userConfiguration2
  );

  const [bom, setBom] = useState([]);
  const [showBomDownloadForm, setShowBomDownloadForm] = useState(false);
  const [isBomLoading, setIsBomLoading] = useState(false); // Add loading state

  const deleteProduct = async (order: Order, productIndex: number) => {
    const productToDelete = order.cart_items[productIndex];
    const filteredProducts = order.cart_items.filter(
      (product: any, index: number) => index !== productIndex
    );
    order.cart_items = filteredProducts;
    try {
      const data = await patchOrder(order);
      dispatch({
        type: ordersConstants.UPDATE_ORDER,
        payload: {
          ...order,
          created_at: data.created_at,
          updated_at: data.updated_at,
        },
      });
      toast.success("Product deleted successfully");
      makeSessionRequest("deleted_product", {
        orderId: order._id,
        product: productToDelete,
      });
    } catch (error) {
      toast.error("Failed to delete product.");
    }
  };

  const updateTableType = (order: Order, productIndex: number) => {
    const currentTableType =
      order.cart_items[productIndex].configuration.Category;
    dispatch({
      type: tableTypeConstants.SET_TABLE_TYPE,
      payload: currentTableType.includes("SHARING")
        ? "Linear Workstation"
        : currentTableType.includes("main partition") ||
          currentTableType.includes("main + return partition")
        ? "Neo WKS"
        : currentTableType,
    });
  };

  const updateProduct = async (order: Order, productIndex: number) => {
    makeSessionRequest("editing_product", {
      orderId: order._id,
      product: order.cart_items[productIndex],
    });
    const currentTableType =
      order.cart_items[productIndex].configuration.Category;
    const currentTableLength =
      order.cart_items[productIndex].configuration.Length;
    const currentTableWidth =
      order.cart_items[productIndex].configuration.Width;

    switch (currentTableType) {
      case "C_Cluster":
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: cCluster,
        });
        break;

      case "Meeting Table":
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: meeting,
        });
        break;

      case "Discussion Table":
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: discussion,
        });
        break;
      case "Cabin Table":
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: cabinTable,
        });
        break;
      case "Height Adjustable Table":
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: hat,
        });
        break;
      case "120° Workstation":
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: onetwentydegree,
        });
        break;
      case "Plan System Linear":
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: plansystem,
        });
        break;
      default:
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: workstation,
        });
    }

    dispatch({ type: ordersConstants.SET_CURRENT_ORDER, payload: order });
    updateTableType(order, productIndex);
    dispatch({
      type: tableInfoConstants.SET_TABLE_INFO,
      payload: `${currentTableLength}W x ${currentTableWidth}D`,
    });
    history.push("/configurator/" + productIndex);
  };

  const handleBomDownloadClick = async (order: Order, productIndex: any) => {
    setIsBomLoading(true); // Set loading state to true
    makeSessionRequest("clicked_bom", {
      orderId: order._id,
      customerName: order.customer.name,
    });
    updateTableType(order, productIndex);
    // if (tableType === "Workstation" || tableType === "Desking WKS") {
    const userConfigurationFiltered = Object.entries(
      order.cart_items[productIndex].configuration
    )
      .filter(
        ([key, value]) => value !== null && value !== "None" && value !== "none"
      )
      .reduce((acc: any, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    // const result = {
    //   ...userConfigurationFiltered,
    // };
    const result = {
      ...userConfigurationFiltered,

      ...(tableType === "Linear Workstation" &&
      order.cart_items[productIndex].configuration["Sharing Legs"]
        ? {
            "Mid Legs": "Intermediate Leg with Cover",
            Beams: "CONNECTING BAR",
            "T Legs": "Intermediate T Leg",
          }
        : {}),
      //@ts-ignore
      ...(tableType === "Linear Workstation" &&
      order.cart_items[productIndex].configuration["Non Sharing Legs"]
        ? {
            "Mid Legs": "Intermediate Leg with Cover",
            Beams: "CONNECTING BAR",
            "T Legs": "Intermediate T Leg",
          }
        : {}),
      ...(tableType === "Linear Workstation" &&
      //@ts-ignore
      order.cart_items[productIndex].configuration["Raceway Sharing"] ===
        "Gromet with vertical raceway"
        ? {
            "Raceway Sharing": "Grommet",
            "Vertical Raceway": "Vertical Raceway",
          }
        : //@ts-ignore
        order.cart_items[productIndex].configuration["Raceway Sharing"] ===
          "Gromet With faceplate Cutting"
        ? {
            "Raceway Sharing": "Grommet",
            "Horizontal raceway Sharing":
              "Horizontal raceway sharing with Faceplate Cutting",
          }
        : //@ts-ignore
        order.cart_items[productIndex].configuration["Raceway Sharing"] ===
          "Flip Up"
        ? {
            "Horizontal raceway Sharing":
              "Horizontal Raceway Sharing with switch socket",
          }
        : {}),
      ...(tableType === "Linear Workstation" &&
      //@ts-ignore
      order.cart_items[productIndex].configuration["Raceway Non Sharing"] ===
        "Gromet with vertical raceway"
        ? {
            "Raceway Non Sharing": "Grommet",
            "Vertical Raceway": "Vertical Raceway",
          }
        : //@ts-ignore
        order.cart_items[productIndex].configuration["Raceway Non Sharing"] ===
          "Gromet With faceplate Cutting"
        ? {
            "Raceway Non Sharing": "Grommet",
            "Horizontal raceway Non-sharing":
              "Horizontal raceway Non-sharing with Faceplate Cutting",
          }
        : //@ts-ignore
        order.cart_items[productIndex].configuration["Raceway Non Sharing"] ===
          "Flip Up"
        ? {
            "Horizontal raceway Non-Sharing":
              "Horizontal Raceway Non Sharing with switch socket",
          }
        : {}),
      //@ts-ignore
      ...(tableType === "Linear Workstation" &&
      order.cart_items[productIndex].configuration["Raceway Sharing"] !==
        "Flip Up" &&
      order.cart_items[productIndex].configuration[
        "Wire Management's Dimensions Sharing"
      ]
        ? { "Wire Management's Dimensions Sharing": "None" }
        : {}),
      //@ts-ignore
      ...(tableType === "Linear Workstation" &&
      order.cart_items[productIndex].configuration["Raceway Non Sharing"] !==
        "Flip Up" &&
      order.cart_items[productIndex].configuration[
        "Wire Management's Dimensions Non Sharing"
      ]
        ? { "Wire Management's Dimensions Non Sharing": "None" }
        : {}),
    };

    axios
      .post(pricingApi + "price_officemate_new", result, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        setBom(data.components);
        setShowBomDownloadForm(true);
        setIsBomLoading(false); // Set loading state to false
      })
      .catch((err) => {
        setIsBomLoading(false); // Set loading state to false
      });
  };

  const handleClose = (e: any) => {
    if (e.target === e.currentTarget) {
      setShowBomDownloadForm(false);
    }
  };
  return (
    <>
      {(showBomDownloadForm || isBomLoading) && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={handleClose}
        >
          <div
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "5px",
              width: "80%",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            {isBomLoading ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  padding: "20px",
                }}
              >
                Loading...
              </div>
            ) : (
              <BomTable bom={bom} />
            )}
          </div>
        </div>
      )}
      <table {...getTableProps()} className="border border-b">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr
              className="border-0 border-b-2 text-left px-2"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column: any) => (
                <th className="p-4 px-8" {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, i: number) => {
            prepareRow(row);
            return (
              <tr
                className="text-left border-0 border-t"
                {...row.getRowProps()}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <td className="py-2 px-8" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
                <td>
                  <button
                    onClick={() => handleBomDownloadClick(order, i)}
                    className={`underline font-bold mr-4 xl:text-lg`}
                  >
                    BOM
                  </button>
                </td>
                <td>
                  <img
                    src={editIcon}
                    className="cursor-pointer mr-4"
                    alt=""
                    onClick={() => updateProduct(order, i)}
                  />
                </td>
                <td>
                  <img
                    src={deleteIcon}
                    className="cursor-pointer mr-4"
                    alt=""
                    onClick={() => deleteProduct(order, i)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

function ProductsTable({ order }: { order: Order }) {
  const { cart_items } = order;
  const [isUpdatingQty, setIsUpdatingQty] = useState(false);
  const dispatch = useAppDispatch();
  const data = useMemo(
    () =>
      cart_items.map((product) => {
        return {
          ...product,
          totalPrice: (product.bom.totalPrice || "---") + " ₹",
        };
      }),
    [cart_items]
  );

  const changeQuantity = useCallback(
    (productIdx: number, increment: number) => {
      const updatedOrder = { ...order };
      const updatedProduct = { ...updatedOrder.cart_items[productIdx] };
      if (updatedProduct.quantity + increment < 1) {
        return;
      }
      if (increment > 1) {
        if (increment !== updatedProduct.quantity) {
          updatedProduct.quantity = increment;
        } else return;
      } else {
        updatedProduct.quantity = updatedProduct.quantity + increment;
      }
      updatedOrder.cart_items[productIdx] = updatedProduct;

      try {
        setIsUpdatingQty(true);
        patchOrder(updatedOrder).then((data) => {
          dispatch({
            type: ordersConstants.UPDATE_ORDER,
            payload: JSON.parse(JSON.stringify(updatedOrder)),
          });
          toast.success("Product updated successfully");
          makeSessionRequest("update_quantity", {
            orderId: order._id,
            productIndex: productIdx,
            quantity: updatedOrder.cart_items[productIdx].quantity,
          });
          setIsUpdatingQty(false);
        });
      } catch (error) {
        toast.error("Failed to update product.");
        makeSessionRequest("update_quantity_failed", {
          orderId: order._id,
          productIndex: productIdx,
          quantity: updatedOrder.cart_items[productIdx].quantity,
        });
        setIsUpdatingQty(false);
      }
    },
    [dispatch, order]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        Cell: ({ row }: any) => (
          <div className="max-w-md">
            <div className="font-bold mb-2">
              {row.original.configuration.name}
            </div>
            <div className="flex flex-col">
              Description:
              <textarea
                disabled
                style={{ minHeight: "6rem", resize: "none" }}
                className="h-24 w-72 overflow-scroll border px-2 max-h-24"
              >
                {row.original.product.split(", ").join("\n")}
              </textarea>
            </div>
          </div>
        ),
      },
      {
        Header: () => "Qty",
        id: "qty",
        Cell: ({ row, index }: any) => {
          return (
            <span className="flex ">
              <button
                disabled={isUpdatingQty}
                onClick={() => changeQuantity(row.index, -1)}
                className="px-2 mr-1 bg-gray-400"
              >
                -
              </button>
              <input
                id={row.original.thumbnail}
                type="number"
                placeholder={row.original.quantity}
                onChange={(e) => {
                  let timer: any;
                  if (timer) {
                    clearTimeout(timer);
                  }
                  timer = setTimeout(
                    () => changeQuantity(row.index, Number(e.target.value)),
                    800
                  );
                }}
                className={"w-16 text-center placeholder-black"}
                onFocus={() => {
                  const ele: any = document.getElementById(
                    row.original.thumbnail
                  );
                  ele.placeholder = "";
                }}
                onBlur={() => {
                  const ele: any = document.getElementById(
                    row.original.thumbnail
                  );
                  ele.placeholder = row.original.quantity;
                }}
              />{" "}
              <button
                disabled={isUpdatingQty}
                onClick={() => changeQuantity(row.index, 1)}
                className="px-2 ml-1 bg-gray-400"
              >
                +
              </button>
            </span>
          );
        },
      },
      {
        Header: "Total Price",
        Cell: ({ row }: any) => (
          <span>Rs {row.original.quantity * row.original.cost}</span>
        ),
      },
      {
        Header: () => "Thumbnail",
        id: "expander",
        Cell: ({ row }: any) => (
          <span>
            <img
              className="w-24 ml-auto mr-auto"
              src={row.original.thumbnail}
              alt=""
            />
          </span>
        ),
      },
    ],
    [changeQuantity, isUpdatingQty]
  );
  if (!data || data.length === 0) return <div>No products added</div>;

  return <Table columns={columns} data={data} order={order} />;
}

export default ProductsTable;
