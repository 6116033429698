import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import logo from "../assets/logo.png";
// import add from "../assets/icons/add-white.svg";
import NewOrderForm from "../Components/NewOrderForm";
import ProductsTable from "../Components/OrdersTable";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { ordersConstants } from "../store/actions/types";
import { Order } from "../types/types";
import { getOrders, searchOrders } from "../utils/ordersApi";
import closeIcon from "../assets/icons/close.svg";
import profileIcon from "../assets/icons/profile.svg";
import settingsIcon from "../assets/icons/settings.svg";
import GenerateQuotationForm from "../Components/generateQuotationModal";
import { useHistory } from "react-router-dom";
import { makeSessionRequest } from "../utils/makeSessionRequest";
import { getIsSuperAdmin } from "../utils/getIsSuperAdmin";
import ProfileSettingsForm from "../Components/ProfileSettingsForm";
import { getUserFromLocalStorage } from "../utils/localStorage";
export default function Dashboard() {
  const [showNewOrderForm, setShowNewOrderForm] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<Order | null>(null);
  const dispatch = useAppDispatch();
  const orders = useAppSelector((state) => state.orders.orders);
  const user = useAppSelector((state) => state.user);
  const loadingOrders = useAppSelector((state) => state.orders.loadingOrders);
  const currentPage = useAppSelector((state) => state.orders.currentPage);
  const pageSize = useAppSelector((state) => state.orders.pageSize);
  const totalOrders = useAppSelector((state) => state.orders.totalOrders);
  const [searchText, setSearchText] = useState("");
  const searchedOrders = useAppSelector((state) => state.orders.searchedOrders);
  const [isSearched, setIsSearched] = useState(false);
  const [showGenerateQuotationForm, setShowGenerateQuotationForm] =
    useState(false);
  const [showBomDownloadForm, setShowBomDownloadForm] = useState(false);
  const [showProfileSettings, setShowProfileSettings] = useState(false);

  const [showDealerSettings, setShowDealerSettings] = useState(false);
  const history = useHistory();

  useEffect(() => {
    makeSessionRequest("dashboard_view");
    const superAdmin = getIsSuperAdmin();

    const user = getUserFromLocalStorage();
    if (superAdmin || (user && user.role === 2)) {
      setShowDealerSettings(true);
    }
  }, []);
  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: ordersConstants.SET_LOADING_ORDERS, payload: true });
        const orders = (await getOrders(currentPage, pageSize)) || [];
        dispatch({ type: ordersConstants.SET_ORDERS, payload: orders._items });
        dispatch({
          type: ordersConstants.SET_TOTAL_ORDERS,
          payload: orders.totalOrders,
        });
        dispatch({ type: ordersConstants.SET_LOADING_ORDERS, payload: false });
      } catch (error) {
        console.error(error, "ERROR");
        toast.error("Failed to fetch orders");
        dispatch({ type: ordersConstants.SET_LOADING_ORDERS, payload: false });
      }
    })();
  }, [dispatch, user.id, user.role, currentPage, pageSize, totalOrders]);

  const onSearchOrders = async (searchText: string) => {
    setIsSearched(true);
    makeSessionRequest("searched_orders", { searchText });
    if (searchText) {
      try {
        dispatch({
          type: ordersConstants.SET_LOADING_ORDERS,
          payload: true,
        });
        const orders = await searchOrders(searchText);
        if (orders._items.length === 0) {
          toast.error(`No orders found for ${searchText}`);
          dispatch({
            type: ordersConstants.SET_LOADING_ORDERS,
            payload: false,
          });
          setIsSearched(false);
          return;
        } else {
          dispatch({
            type: ordersConstants.SET_SEARCHED_ORDERS,
            payload: orders._items,
          });
          dispatch({
            type: ordersConstants.SET_TOTAL_ORDERS,
            payload: orders.totalOrders,
          });
          dispatch({
            type: ordersConstants.SET_LOADING_ORDERS,
            payload: false,
          });
        }
      } catch (error) {
        toast.error("Failed to search orders");
        dispatch({
          type: ordersConstants.SET_LOADING_ORDERS,
          payload: false,
        });
      }
    }
  };

  const clearSearch = () => {
    setIsSearched(false);
    setSearchText("");
    dispatch({
      type: ordersConstants.SET_SEARCHED_ORDERS,
      payload: [],
    });
  };

  useEffect(() => {
    (async () => {
      if ((searchText === "" || !searchText) && searchedOrders.length > 0) {
        clearSearch();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText, searchedOrders]);

  const logout = () => {
    makeSessionRequest("user_logged_out");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("userTags");
    dispatch({ type: "LOGOUT", payload: {} });
    window.location.reload();
  };

  const closeNewOrderForm = () => {
    setShowNewOrderForm(false);
  };

  const editOrder = (order: Order) => {
    makeSessionRequest("clicked_edit_order", { orderId: order._id });
    setCurrentOrder(order);
    setShowNewOrderForm(true);
  };

  return (
    <div className="pb-16">
      <NewOrderForm
        show={showNewOrderForm}
        closeNewOrderForm={closeNewOrderForm}
        currentOrder={currentOrder}
      />
      <GenerateQuotationForm
        show={showGenerateQuotationForm}
        closeForm={() => setShowGenerateQuotationForm(false)}
      />
      <ProfileSettingsForm
        show={showProfileSettings}
        closeForm={() => setShowProfileSettings(false)}
      />
      <div className="flex items-center py-4 px-8">
        <img src={logo} className=" w-62 h-auto" alt="" />

        <div className=" ml-8 md:ml-auto flex justify-center">
          <button
            onClick={() => setShowProfileSettings(true)}
            className="bg-500 px-4 py-2 mr-5 tracking-wider text-white xl:text-xl"
            style={{ backgroundColor: "#e41b23" }}
          >
            <img src={profileIcon} className="w-6 h-6" alt="" />
          </button>
          <button
            onClick={() => {
              makeSessionRequest("clicked_settings");
              history.push("/settings");
            }}
            className={` bg-500 px-4 py-2 mr-5 tracking-wider text-white xl:text-xl ${
              showDealerSettings ? "" : "hidden"
            }`}
            style={{ backgroundColor: "#e41b23" }}
          >
            <img src={settingsIcon} className="w-6 h-6" alt="" />
          </button>
          <button
            onClick={logout}
            className="bg-500 px-4 py-2 tracking-wider text-white xl:text-xl"
            style={{ backgroundColor: "#e41b23" }}
          >
            Logout
          </button>
        </div>
      </div>
      <div
        style={{ maxWidth: "1800px" }}
        // style={{ width: "max-content" }}
        className="xs:px-16 px-2 2xl:px-16 xl:px-10 mt-8 ml-auto mr-auto"
      >
        <div className="flex items-center mb-8 w-full">
          {orders?.length > 0 && (
            <h3 className="text-xl xl:text-2xl font-bold">Quotations:</h3>
          )}
          {orders?.length > 0 && (
            <div
              className="flex justify-between border transition-all ml-auto mr-auto"
              style={{ width: "30rem" }}
            >
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                placeholder="Enter search text"
                className="focus:outline-none w-full px-4"
              />
              <div className="flex items-center relative">
                {searchText && (
                  <img
                    src={closeIcon}
                    alt="clear"
                    className="absolute -left-8 z-index-10"
                    onClick={() => {
                      makeSessionRequest("search_cleared");
                      clearSearch();
                    }}
                  />
                )}
                <span
                  className="bg-500 px-2 py-2 text-white xl:text-xl min-w-max cursor-pointer"
                  style={{ backgroundColor: "#e41b23" }}
                  onClick={() => onSearchOrders(searchText)}
                >
                  search
                </span>
              </div>
            </div>
          )}

          <button
            onClick={() => {
              setCurrentOrder(null);
              setShowNewOrderForm(true);
              makeSessionRequest("clicked_on_create_quotation");
            }}
            className="ml-auto bg-500 px-2 py-2 tracking-wider text-white xl:text-xl min-w-max"
            style={{ backgroundColor: "#e41b23" }}
          >
            ADD NEW CUSTOMER
          </button>
        </div>

        {orders?.length === 0 ||
        (isSearched && searchedOrders?.length === 0) ? (
          loadingOrders ? (
            <div className="text-center mt-2 font-bold">Loading Data...</div>
          ) : (
            <div className="text-center mt-2 font-bold">No orders</div>
          )
        ) : (
          <ProductsTable
            editOrder={editOrder}
            showGenerateQuotationForm={() => setShowGenerateQuotationForm(true)}
            showBomDownloadForm={() => setShowBomDownloadForm(true)}
          />
        )}
      </div>
    </div>
  );
}
