// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "750 Dia",
        required: false,
        disableOptions: [
          "@Work",
          "Scope",
          "Io",
          "Styl",
          "Grommet",
          "Flip Up With Vertical Raiser",
          "Flip Up With Vertebrae",
        ],
        enableOptions: ["Eco", "Pole"],
        options: [
          {
            name: "750 Dia",
            "Table Top Options": "Plain",
            Category: "Discussion Table",

            size: 1.01,
            Dia: 750,
            Length: 750,
            Depth: 750,
            thumbnail: "assets/system/thumbnails/discussion/1.png",
            productId: "664f06839cb5c9d4cb118d5d",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            disableOptions: [
              "@Work",
              "Scope",
              "Io",
              "Styl",
              "Grommet",
              "Flip Up With Vertical Raiser",
              "Flip Up With Vertebrae",
            ],
            enableOptions: ["Eco", "Pole"],
          },
        ],
      },
      {
        name: "900 Dia",
        required: false,
        options: [
          {
            name: "900 Dia",
            "Table Top Options": "Plain",
            Category: "Discussion Table",
            size: 1.02,
            Dia: 900,
            Length: 900,
            Depth: 900,
            thumbnail: "assets/system/thumbnails/discussion/1.png",
            productId: "6650412b115de7b77e49c8d2",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            disableOptions: [
              "Styl",
              "Grommet",
              "Flip Up With Vertical Raiser",
              "Flip Up With Vertebrae",
            ],
            enableOptions: ["@Work", "Io", "Eco", "Pole", "Scope"],
          },
          // {
          //   name: "1050 Dia",
          //   size: 1050,
          //   Dia: 1050,
          //   thumbnail: "assets/system/placeholder.svg",
          //   productId: "665041519e80450b27ca72eb",
          //   tableTop: ["table_top"],
          //   leftLegs: ["Leg"],
          //   rightLegs: ["Leg"],
          //   disableOptions: [""],
          //   enableOptions: [""],
          // },
          // {
          //   name: "1200 Dia",
          //   size: 1200,
          //   Dia: 1200,
          //   thumbnail: "assets/system/placeholder.svg",
          //   productId: "6650417eef20dc6396c27c14",
          //   tableTop: ["table_top"],
          //   leftLegs: ["Leg"],
          //   rightLegs: ["Leg"],
          //   disableOptions: ["Pole", ""],
          //   enableOptions: [""],
          // },
        ],
      },
      {
        name: "1050 Dia",
        required: false,
        options: [
          {
            name: "1050 Dia",
            "Table Top Options": "Plain",
            Category: "Discussion Table",
            size: 1.03,
            Dia: 1050,
            Length: 1050,
            Depth: 1050,
            thumbnail: "assets/system/thumbnails/discussion/1.png",
            productId: "665041519e80450b27ca72eb",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            disableOptions: [
              "Grommet",
              "Flip Up With Vertical Raiser",
              "Flip Up With Vertebrae",
            ],
            enableOptions: ["Styl", "@Work", "Io", "Eco", "Pole", "Scope"],
          },
          // {
          //   name: "1200 Dia",
          //   size: 1200,
          //   Dia: 1200,
          //   thumbnail: "assets/system/placeholder.svg",
          //   productId: "6650417eef20dc6396c27c14",
          //   tableTop: ["table_top"],
          //   leftLegs: ["Leg"],
          //   rightLegs: ["Leg"],
          //   disableOptions: ["Pole", ""],
          //   enableOptions: [""],
          // },
        ],
      },
      {
        name: "1200 Dia",
        required: false,
        options: [
          {
            name: "1200 Dia",
            "Table Top Options": "Plain",
            Category: "Discussion Table",
            size: 1.04,
            Length: 1200,
            Depth: 1200,
            Dia: 1200,
            thumbnail: "assets/system/thumbnails/discussion/1.png",
            productId: "6650417eef20dc6396c27c14",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            disableOptions: [
              "Pole",
              "Grommet",
              "Flip Up With Vertical Raiser",
              "Flip Up With Vertebrae",
            ],

            enableOptions: ["Styl", "@Work", "Io", "Eco", "Scope"],
          },
        ],
      },
    ],
  },

  {
    name: "Legs",
    wireManagementNone: true,
    multiSelect: false,
    options: [
      {
        name: "750 Dia",
        required: false,
        options: [
          {
            name: "Leg",
            wireManagementNone: true,
            required: false,
            options: [
              {
                name: "Eco",
                enableFeatures: ["Eco Size Options"],
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                enableOptions: ["Grommet"],
                disableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/dd950655-96a0-4b88-ab12-f1f24169161b/27_5_2024/1719495164995/eco-leg.glb",
                  },
                  {
                    type: "show",
                    node: "connecter",
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },

              {
                name: "Pole",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "/assets/components/Perform-D3.png",
                disableOptions: ["Grommet"],
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/d1835fa7-98f3-4c42-8829-b9c1fb81bbd3/29_4_2024/1716991428101/Pole-leg.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "FlipUp_vertebrae",
                      "FlipUp",
                      "gorment",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
            ],
          },
          {
            name: "Eco Size Options",
            required: false,
            options: [
              {
                name: "40 mm",
              },
              {
                name: "50 mm",
              },
            ],
          },
        ],
      },
      {
        name: "900 Dia",
        required: false,
        wireManagementNone: true,
        options: [
          {
            name: "Leg",
            required: false,
            options: [
              {
                name: "Eco",
                enableFeatures: ["Eco Size Options"],
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                enableOptions: ["Grommet"],
                disableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/dd950655-96a0-4b88-ab12-f1f24169161b/27_5_2024/1719495164995/eco-leg.glb",
                  },
                  {
                    type: "show",
                    node: "connecter",
                  },
                  {
                    type: show,
                    node: "gorment",
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "@Work",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                disableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                enableOptions: ["Grommet"],
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/2a385636-2f26-4f39-8c28-4009c6f34801/2_6_2024/1719928606094/-work-leg.glb",
                  },
                  {
                    type: "hide",
                    node: "connecter",
                  },
                  {
                    type: show,
                    node: "gorment",
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Scope",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                disableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                enableOptions: ["Grommet"],
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/abfb58cd-6891-4a9d-ae0f-57ee15b165b6/27_5_2024/1719495480800/scope-leg.glb",
                  },
                  {
                    type: "hide",
                    node: "connecter",
                  },
                  {
                    type: show,
                    node: "gorment",
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Io",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                disableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                enableOptions: ["Grommet"],
                rules: [
                  {
                    type: "hide",
                    node: "connecter",
                  },
                  {
                    type: show,
                    node: "gorment",
                  },
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/06478887-7a41-454a-a268-5751923f0f22/1_6_2024/1719842212586/IO.glb",
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Pole",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "/assets/components/Perform-D3.png",
                disableOptions: ["Flip Up With Vertical Raiser", "Grommet"],
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/d1835fa7-98f3-4c42-8829-b9c1fb81bbd3/29_4_2024/1716991428101/Pole-leg.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "FlipUp_vertebrae",
                      "FlipUp",
                      "gorment",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
            ],
          },
          {
            name: "Eco Size Options",
            required: false,
            options: [
              {
                name: "40 mm",
              },
              {
                name: "50 mm",
              },
            ],
          },
        ],
      },
      {
        name: "1050 Dia",
        multi: true,
        required: false,
        wireManagementNone: true,
        options: [
          {
            name: "Leg",
            required: false,
            options: [
              {
                name: "Eco",
                enableFeatures: ["Eco Size Options"],
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                enableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                disableOptions: ["Grommet"],

                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/dd950655-96a0-4b88-ab12-f1f24169161b/27_5_2024/1719495164995/eco-leg.glb",
                  },
                  {
                    type: "show",
                    node: "connecter",
                  },
                  {
                    type: hide,
                    node: "gorment",
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "@Work",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                enableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                disableOptions: ["Grommet"],
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/2a385636-2f26-4f39-8c28-4009c6f34801/2_6_2024/1719928606094/-work-leg.glb",
                  },
                  {
                    type: "hide",
                    node: "connecter",
                  },
                  {
                    type: hide,
                    node: "gorment",
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Scope",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                enableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                disableOptions: ["Grommet"],
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/abfb58cd-6891-4a9d-ae0f-57ee15b165b6/27_5_2024/1719495480800/scope-leg.glb",
                  },
                  {
                    type: "hide",
                    node: "connecter",
                  },
                  {
                    type: hide,
                    node: "gorment",
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Io",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                enableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ], ///
                disableOptions: ["Grommet"],
                rules: [
                  {
                    type: "hide",
                    node: "connecter",
                  },
                  {
                    type: hide,
                    node: "gorment",
                  },
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/06478887-7a41-454a-a268-5751923f0f22/1_6_2024/1719842212586/IO.glb",
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Styl",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "/assets/components/SwitchAngularSharing.png",

                disableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                enableOptions: ["Grommet"],
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/325c7052-aadc-44cd-a918-b391c2f4b6d7/2_6_2024/1719926849406/styl.glb",
                  },
                  {
                    type: "hide",
                    node: "connecter",
                  },
                  {
                    type: hide,
                    node: ["FlipUp_vertebrae", "FlipUp"],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Pole",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "/assets/components/Perform-D3.png",
                disableOptions: ["Flip Up With Vertical Raiser", "Grommet"],
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/d1835fa7-98f3-4c42-8829-b9c1fb81bbd3/29_4_2024/1716991428101/Pole-leg.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "FlipUp_vertebrae",
                      "FlipUp",
                      "gorment",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
            ],
          },
          {
            name: "Eco Size Options",
            required: false,
            options: [
              {
                name: "40 mm",
              },
              {
                name: "50 mm",
              },
            ],
          },
        ],
      },
      {
        name: "1200 Dia",
        wireManagementNone: true,
        required: false,
        options: [
          {
            name: "Leg",
            required: false,
            options: [
              {
                name: "Eco",
                enableFeatures: ["Eco Size Options"],
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                enableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                disableOptions: ["Grommet"],

                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/dd950655-96a0-4b88-ab12-f1f24169161b/27_5_2024/1719495164995/eco-leg.glb",
                  },
                  {
                    type: "show",
                    node: "connecter",
                  },
                  {
                    type: hide,
                    node: "gorment",
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "@Work",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                enableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                disableOptions: ["Grommet"],
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/2a385636-2f26-4f39-8c28-4009c6f34801/2_6_2024/1719928606094/-work-leg.glb",
                  },
                  {
                    type: "hide",
                    node: "connecter",
                  },
                  {
                    type: hide,
                    node: "gorment",
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Scope",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                enableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                disableOptions: ["Grommet"],
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/abfb58cd-6891-4a9d-ae0f-57ee15b165b6/27_5_2024/1719495480800/scope-leg.glb",
                  },
                  {
                    type: "hide",
                    node: "connecter",
                  },
                  {
                    type: hide,
                    node: "gorment",
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Io",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                enableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                disableOptions: ["Grommet"],
                rules: [
                  {
                    type: "hide",
                    node: "connecter",
                  },
                  {
                    type: hide,
                    node: "gorment",
                  },
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/06478887-7a41-454a-a268-5751923f0f22/1_6_2024/1719842212586/IO.glb",
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Styl",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                disableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                ],
                enableOptions: ["Grommet"],
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/325c7052-aadc-44cd-a918-b391c2f4b6d7/2_6_2024/1719926849406/styl.glb",
                  },
                  {
                    type: "hide",
                    node: "connecter",
                  },
                  {
                    type: hide,
                    node: ["FlipUp_vertebrae", "FlipUp"],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Pole",
                disableFeatures: ["Eco Size Options"],
                thumbnail: "/assets/components/Perform-D3.png",
                disableOptions: [
                  "Flip Up With Vertical Raiser",
                  "Flip Up With Vertebrae",
                  "Grommet",
                ], /////
                rules: [
                  {
                    type: "replacePart",
                    node: "leg",
                    pid: "https://content.helloviewer.io/uploads/d1835fa7-98f3-4c42-8829-b9c1fb81bbd3/29_4_2024/1716991428101/Pole-leg.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "FlipUp_vertebrae",
                      "FlipUp",
                      "gorment",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
            ],
          },
          {
            name: "Eco Size Options",
            required: false,
            options: [
              {
                name: "40 mm",
              },
              {
                name: "50 mm",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    options: [
      {
        name: "750 Dia",
        required: false,
        options: [
          {
            name: "Wire Management",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: ["gorment", "FlipUp_vertebrae", "FlipUp"],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                rules: [
                  {
                    type: show,
                    node: ["gorment"],
                  },
                  {
                    type: hide,
                    node: ["FlipUp_vertebrae", "FlipUp", "Table_top"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "900 Dia",
        required: false,
        options: [
          {
            name: "Wire Management",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: ["gorment", "FlipUp_vertebrae", "FlipUp"],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                rules: [
                  {
                    type: show,
                    node: ["gorment"],
                  },
                  {
                    type: hide,
                    node: ["FlipUp_vertebrae", "Table_top", "FlipUp"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "1050 Dia",
        required: false,
        options: [
          {
            name: "Wire Management",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: ["gorment", "FlipUp_vertebrae", "FlipUp"],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/FlipUp.png",
                rules: [
                  {
                    type: hide,
                    node: ["gorment", "Table_top", "FlipUp_vertebrae"],
                  },
                  {
                    type: show,
                    node: ["FlipUp"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/FlipUp.png",
                rules: [
                  {
                    type: hide,
                    node: ["gorment", "FlipUp", "Table_top"],
                  },
                  {
                    type: show,
                    node: ["FlipUp_vertebrae"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                rules: [
                  {
                    type: show,
                    node: ["gorment"],
                  },
                  {
                    type: hide,
                    node: ["FlipUp_vertebrae", "FlipUp", "Table_top"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "1200 Dia",
        required: false,
        options: [
          {
            name: "Wire Management",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: ["gorment", "FlipUp_vertebrae", "FlipUp"],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/FlipUp.png",
                rules: [
                  {
                    type: hide,
                    node: ["gorment", "FlipUp_vertebrae", "Table_top"],
                  },
                  {
                    type: show,
                    node: ["FlipUp"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/FlipUp.png",
                rules: [
                  {
                    type: hide,
                    node: ["gorment", "FlipUp", "Table_top"],
                  },
                  {
                    type: show,
                    node: ["FlipUp_vertebrae"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                rules: [
                  {
                    type: show,
                    node: ["gorment"],
                  },
                  {
                    type: hide,
                    node: ["FlipUp_vertebrae", "FlipUp", "Table_top"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "750 Dia",
        required: false,

        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "900 Dia",
        required: false,

        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "1050 Dia",
        required: false,

        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "1200 Dia",
        required: false,

        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "750 Dia",
        required: false,

        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "900 Dia",
        required: false,

        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "1050 Dia",
        required: false,

        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "1200 Dia",
        required: false,

        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
