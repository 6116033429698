import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import logo from "../assets/logo.png";
import { useAppDispatch } from "../hooks/hooks";
import { userConstants } from "../store/actions/types";
import { getApplicationRoles } from "../utils/getApplicationRoles";
import { makeSessionRequest } from "../utils/makeSessionRequest";

export default function Login() {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState<{ requestId: string } | undefined>();
  const [otp, setOtp] = useState("");
  const [generatingOtp, setGeneratingOtp] = useState(false);
  const [verifyingOtp, setVerifyingOtp] = useState(false);

  const history = useHistory();

  const dispatch = useAppDispatch();

  const sendOtp = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (
      !email.match(/^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/)
    ) {
      alert("Invalid email");
      return;
    }
    setGeneratingOtp(true);
    axios
      .post(process.env.REACT_APP_HELLOAR_API + "auth/send_otp", {
        email: email,
        app_source: "web",
      })
      .then((res) => {
        setResponse({ requestId: res.data.request_id });
        setGeneratingOtp(false);
      })
      .catch((err) => {
        toast.error("User not found");
        setGeneratingOtp(false);
      });
  };

  const verifyOtp = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!response?.requestId) {
      return;
    }
    setVerifyingOtp(true);
    axios
      .post(process.env.REACT_APP_HELLOAR_API + "auth/verify_otp", {
        request_id: response.requestId,
        otp: parseInt(otp),
      })
      .then(async (res) => {
        const userId = res.data.account_id;

        const roleData = await getApplicationRoles({
          userId,
        });
        const requiredRoleData = roleData.data._items.filter(
          (item: any) =>
            item.application === process.env.REACT_APP_APPLICATION_ID
        );
        if (requiredRoleData.length > 0) {
          localStorage.setItem(
            "userTags",
            JSON.stringify(requiredRoleData[0].tags)
          );
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...res.data,
              role: requiredRoleData[0].role,
              viewer: requiredRoleData[0].viewer,
            })
          );
          dispatch({
            type: userConstants.SET_USER,
            payload: { ...res.data, token: res.data.access_token, email },
          });
          history.push("/dashboard");

          setVerifyingOtp(false);

          setResponse(undefined);
          setOtp("");
          makeSessionRequest("user_logged_in");
        } else {
          setVerifyingOtp(false);
          setResponse(undefined);
          setOtp("");
          window.alert("You're not allowed to use this app.");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Invalid OTP");
        setVerifyingOtp(false);
      });
  };

  return (
    <div className="sm:px-16 px-4 h-screen flex items-center flex-col ">
      <div className="flex flex-grow flex-wrap flex-col w-full justify-center items-center">
        {response?.requestId ? (
          <form
            onSubmit={verifyOtp}
            style={{ height: "max-content" }}
            className=" w-full sm:w-1/2 lg:w-1/3 sm:p-8 md:p-16 md:pt-0 p-4 flex flex-col shadow-lg items-center justify-between"
          >
            <div className="flex justify-center py-4 mb-6 flex-grow-0">
              <img src={logo} className=" w-62 h-auto" alt="" />
            </div>
            <h3 className="text-2xl font-bold mb-4 text-center">
              OTP sent to <br /> {email}
            </h3>
            <h4>Can you please verify it?</h4>
            <OtpInput
              inputStyle="border font-bold text-2xl md:text-3xl lg:text-4xl focus:outline-none otp-input"
              containerStyle="my-4"
              numInputs={4}
              separator={<span className="mx-1">-</span>}
              value={otp}
              onChange={(otp: string) => setOtp(otp)}
            />
            <h3
              onClick={sendOtp}
              className=" cursor-pointer w-max ml-auto mr-auto mb-8 mt-4 hover:text-customRed underline font-bold text-lg"
            >
              {generatingOtp ? "Generating OTP..." : "Resend OTP"}
            </h3>
            <button
              onClick={verifyOtp}
              style={{ backgroundColor: verifyingOtp ? "#a9301c" : "#57ca85" }}
              className={`${
                verifyingOtp ? "text-gray-300" : "text-white"
              } px-4 py-4   font-bold text-2xl w-full`}
            >
              {verifyingOtp ? "Verifying OTP..." : "Confirm"}
            </button>
            <h3
              onClick={() => setResponse(undefined)}
              className=" cursor-pointer w-max ml-auto mr-auto mb-0 mt-8 hover:text-customRed underline font-bold text-lg"
            >
              Use different email address
            </h3>
          </form>
        ) : (
          <form
            onSubmit={sendOtp}
            style={{ height: "max-content" }}
            className=" w-full  sm:w-1/2 xl:w-1/3 sm:p-8 md:p-16 md:pt-0 p-4 mt-4 flex flex-col shadow-lg justify-between"
          >
            <div className="flex justify-center py-4 mb-6 flex-grow-0">
              <img src={logo} className=" w-62 h-auto" alt="" />
            </div>
            <h3 className="text-xl mb-4">Email Address</h3>
            <input
              className="border mb-4 text-xl px-4 py-2 focus:outline-none"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={sendOtp}
              disabled={generatingOtp}
              style={{ backgroundColor: generatingOtp ? "#a9301c" : "#57ca85" }}
              className={`${
                generatingOtp ? "text-gray-300" : "text-white"
              } px-4 py-2 mt-8 font-bold text-2xl `}
            >
              {generatingOtp ? "Generating OTP..." : "Next"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
