import React from "react";
import { Redirect, Route } from "react-router";
import { useAppSelector } from "../hooks/hooks";
import Login from "../Pages/Login";

export const PrivateRoute = ({
  children,
  path,
}: {
  children: React.ReactNode;
  path: string;
}) => {
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  return <Route path={path}>{isAuthenticated ? children : <Redirect to="/login" /> }</Route>;
};
