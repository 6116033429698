import React, { useCallback, useEffect, useRef, useState } from "react";
import useUserConfiguration from "../utils/updateUserConfiguration";
import { pedestalConstants } from "../const/pedestalConst";
import logo from "../assets/logo.png";
import { Viewer3D } from "hello-3d-sdk/packages/sdk";
import { fetchProduct } from "../utils/apiManager";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import "./Preview.css";
import { Feature } from "../types/types";

type Rule = {
  type: string;
  node: string[];
  pid: string;
  textureUrl?: string;
  parameters?: {
    node?: string | string[];
  };
};

const getReplacePart = (partId: string, nodes: string[]) => {
  return {
    parameters: {
      part: partId,
      nodes: nodes,
      position: {
        position: {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: {
          x: "1", //was initially 100x for all x, y, z
          y: "1",
          z: "1",
        },
        rotation: {
          x: "0", //was -90 for featherlite
          y: 0,
          z: 0,
        },
      },
    },
    type: "ReplacePartGlb",
    id: "6cc506e6-0ce8-41dd-8990-c0430fd1f140",
  };
};

const getUnbindPart = (instance: string) => {
  return {
    parameters: {
      instance: instance,
    },
    type: "Unbind",
    id: "ca7d3ec2-486f-4c51-9a9e-1b27d238343e",
  };
};

const getBindPart = (
  partId: string,
  position?: any,
  rotation?: any,
  instance?: string
) => {
  return {
    parameters: {
      part: partId,
      // nodes: nodes,
      position: {
        position: position || {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: {
          x: "1",
          y: "1",
          z: "1",
        },
        rotation: rotation || {
          x: 0,
          y: 0,
          z: 0,
        },
      },
      instance: instance || "6b49a29e-64f8-4f20-a14e-0a5b4414602c",
    },
    type: "BindPart",
    id: "e7b661df-ca20-4ff9-ab9e-4cee7a8ae88b",
  };
};

const createMaterialRuleJSON = (textureUrl: string, nodeName: string) => {
  return {
    id: "5f979442-e8a8-492b-8b02-75e74c82cbac",
    type: "Manual",
    actions: [
      {
        parameters: {
          material: {
            name: nodeName,
            texture: textureUrl,
            center: {
              x: 0.5,
              y: 0.5,
            },
          },
        },
        type: "ChangeMaterial",
      },
    ],
  };
};

export default function Preview({
  productViewerRef,
}: {
  productViewerRef: React.MutableRefObject<any>;
}) {
  const [productViewer, setProductViewer] = useState<any>();
  const [prevProductId, setPrevProductId] = useState<string>();
  const [product, setProduct] = useState<any>();
  const [isPostRenderingDone, setIsPostRenderingDone] = useState(true);
  const [is3dSceneLoading, setIs3dSceneLoading] = useState(false);
  const [sceneLoadingProgress, setSceneLoadingProgress] = useState(0);

  const dispatch = useAppDispatch();
  const prevRules = useRef<{
    [key: string]: Rule[];
  }>({});

  const { getSelectedOption, userConfiguration2, getRules } =
    useUserConfiguration();

  const configData: Feature[] = useAppSelector(
    (state) => state.configData.current
  );
  const option = getSelectedOption("System", userConfiguration2.type);
  const productId = option && option[0] && option[0].productId;

  const bindPart = useCallback(
    (pid: string, position?: any, rotation?: any, instance?: string) => {
      return new Promise((resolve, reject) => {
        const actions = [getBindPart(pid, position, rotation, instance)];

        let ruleJSON = {
          id: "989f39f2-4f73-4259-a670-1f59f6a1821d",
          iconText: "BindPart",
          type: "Manual",
          actions,
        };

        if (productViewerRef) {
          productViewerRef?.current?.runCustomRule(
            { rule: ruleJSON, node: [], completionHandler: () => {} },
            () => {
              resolve(true);
            },
            () => {
              reject(false);
            }
          );
        }
      });
    },
    [productViewerRef]
  );

  const unbindPart = useCallback(
    (instance: string) => {
      return new Promise((resolve, reject) => {
        const actions = [getUnbindPart(instance)];

        let ruleJSON = {
          id: "75fd16c9-70d1-469c-9be9-a94341bd7c8e",
          iconText: "UnbindPart",
          type: "Manual",
          actions,
        };

        if (productViewerRef) {
          productViewerRef.current?.runCustomRule(
            { rule: ruleJSON, node: [], completionHandler: () => {} },
            () => {
              resolve(true);
            },
            () => {
              reject(false);
            }
          );
        }
      });
    },
    [productViewerRef]
  );

  const hideNode = useCallback(
    (nodeName: string, completionHandler: any) => {
      const ruleJSON = {
        id: "4175419f-ace6-4ca4-bf2d-bfe9b53f75c1",
        type: "Manual",
        actions: [
          {
            parameters: {
              node: nodeName, //add correct node here
            },
            type: "HideNode",
            id: "e526d733-de1c-4b6c-bf6a-b61688d2c807",
          },
        ],
      };


      if (productViewerRef) {
        productViewerRef?.current?.runCustomRule({
          rule: ruleJSON,
          node: nodeName,
          completionHandler: () => {
            completionHandler();
          },
        });
      }
    },
    [productViewerRef]
  );

  const showNode = useCallback(
    (nodeName: string, completionHandler: any) => {
      let ruleJSON = {
        id: "4175419f-ace6-4ca4-bf2d-bfe9b53f75c1",
        type: "Manual",
        actions: [
          {
            parameters: {
              node: nodeName, //add correct node here
            },
            type: "ShowNode",
            id: "e526d733-de1c-4b6c-bf6a-b61688d2c807",
          },
        ],
      };

      if (productViewerRef) {
        productViewerRef?.current?.runCustomRule(
          {
            rule: ruleJSON,
            node: nodeName,
            completionHandler: () => {
              completionHandler();
            },
          },
          () => {}
        );
      }
    },
    [productViewerRef]
  );

  const replacePart = useCallback(
    (nodes: string[], pid: string, completionHandlerok: any) => {
      // return new Promise((resolve, reject) => {
      const actions = [getReplacePart(pid, nodes)];

      let ruleJSON = {
        id: "21cbc580-8270-4d23-bc61-f0d27a711af3", //4175419f-ace6-4ca4-bf2d-bfe9b53f75c1",
        iconText: "init",
        type: "Manual",
        actions,
      };

      if (productViewerRef) {
        productViewerRef?.current?.runCustomRule({
          rule: ruleJSON,
          node: null,
          completionHandler: () => {
            completionHandlerok();
          },
        });
      }
      // });
    },[productViewerRef]
  );
    ;
  // ,
  // [productViewerRef]
  // );

  const applyMaterial = useCallback(
    (nodeName: string, textureUrl: string, completionHandler: any) => {
      const ruleJSON = createMaterialRuleJSON(textureUrl, nodeName);

      if (productViewerRef) {
        productViewerRef?.current?.runCustomRule({
          rule: ruleJSON,
          node: nodeName,
          completionHandler: () => {
            completionHandler();
          },
        });
      }
    },
    [productViewerRef]
  );
  
  const runRules = useCallback(
    async (rules: Rule[]) => {
      if (!rules?.length) return; // Return early if no rules
  
      // Move "replacePart" rules to the beginning
      const reorderedArray = rules.sort((a, b) =>
        a.type === "replacePart" && b.type !== "replacePart" ? -1 : 1
      );
  
      // Utility function to extract nodes from a rule
      const getNodes = (rule: Rule): string[] => {
        if (rule.node) {
          return Array.isArray(rule.node) ? rule.node : [rule.node];
        }
        if (rule.parameters?.node) {
          return Array.isArray(rule.parameters.node)
            ? rule.parameters.node
            : [rule.parameters.node];
        }
        return [];
      };
  
      // Function to execute a single rule
      const executeRule = async (index: number): Promise<void> => {
        if (index >= reorderedArray.length) {
          return; // All rules executed
        }
  
        const rule = reorderedArray[index];
        const nodes = getNodes(rule);
  
        return new Promise<void>((resolve) => {
          const completionHandler = () => {
            resolve(); // Proceed to the next rule
          };
    
          try {
            switch (rule.type) {
              case "replacePart":
                if (rule.pid !== undefined) {
                  replacePart(nodes, rule.pid, completionHandler);
                } else {
                  console.error("Missing `pid` for replacePart rule.");
                  completionHandler();
                }
                break;
  
              case "hide":
                nodes.forEach((node) => hideNode(node, completionHandler));
                break;
  
              case "show":
                nodes.forEach((node) => showNode(node, completionHandler));
                break;
  
              case "applyMaterial":
                if (rule.textureUrl) {
                  nodes.forEach((node) =>
                    applyMaterial(node, rule.textureUrl!, completionHandler)
                  );
                } else {
                  console.error("Missing `textureUrl` for applyMaterial rule.");
                  completionHandler();
                }
                break;
  
              default:
                console.error("Unknown rule type:", rule.type);
                completionHandler(); // Skip unrecognized rules
            }
          } catch (error) {
            console.error("Error executing rule:", error);
            completionHandler(); // Proceed even if there’s an error
          }
        }).then(() => executeRule(index + 1)); // Execute the next rule after the current one
      };
  
      // Start executing from the first rule
      await executeRule(0);
    },
    [applyMaterial, hideNode, replacePart, showNode] // Ensure all dependencies are stable
  );

  const postRenderRules = async (rules: any[]) => {
    for (const key in rules) {
      if (productViewerRef) {
        productViewerRef?.current?.runCustomRule(
          { rule: rules[key], node: [], completionHandler: () => {} },
          () => {}
        );
      }
    }
    setSceneLoadingProgress(100);
    setIsPostRenderingDone(true);
  };
  useEffect(() => {
    setPrevProductId(productId);
    dispatch({
      type: "SET_PRODUCT_ID",
      payload: {
        value: productId,
      },
    });
  }, [productId, productViewerRef]);

  const hasObjectValueChanged = useCallback((value: any, prevValue: any) => {
    if (JSON.stringify(value) === JSON.stringify(prevValue)) {
      return false;
    }
    return true;
  }, []);

  useEffect(() => {
    (async () => {
      const rules = getRules();
      let changedNodes: string[] = []
      try {
        for (const item of configData) {
          if (item.name in rules) {
            let ruleKey = item.name    
            if (rules[ruleKey] && rules[ruleKey].length > 0) {
              await runRules(rules[ruleKey]);
            }
          }
        } 
      } catch (error) {
        console.error(error);
      }
      prevRules.current = rules;
    })();
    //run when userConfiguration2 changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConfiguration2]);

  useEffect(() => {
    (async () => {
      const rules = getRules();
      for (const item of configData) {
        if (item.name in rules) {
          let ruleKey = item.name          
          if (rules[ruleKey].length > 0) {
            try {
              await runRules(rules[ruleKey]);
            } catch (error) {
              console.error(error);
            }
          }
        }
      }

      prevRules.current = rules;
    })();
    //run when prevProductId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevProductId]);

  const concatRules = async () => {
    const rules = getRules();
    for (const item of configData) {
      if (item.name in rules) {
        let ruleKey = item.name          
        if (rules[ruleKey] &&rules[ruleKey].length > 0) {
          try {
            await runRules(rules[ruleKey]);
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
    // return concatenatedRules
  };

  const setup3dViewer= async (currentProduct: any) => {
    await runRules(option[0].rules);
    setIsPostRenderingDone(false);

    dispatch({
      type: "SET_PRODUCT",
      payload: {
        value: currentProduct,
      },
    });
    if (productViewerRef?.current) {
      productViewerRef?.current?.getViewer()?.destroy();
      setSceneLoadingProgress(0);
      setIsPostRenderingDone(false);
      setIs3dSceneLoading(true);
      productViewerRef?.current
        ?.getViewer()
        ?.reinitialiseWeb3d(document.getElementById("3d-viewer"));
      productViewerRef?.current?.getViewer()?.init({
        modelDetails: {
          glb: currentProduct.three_dee_model.glb,
        },
        rules: [], //[...(JSON.parse(res?.rules.json).rules ?? [])],
        settings: {
          lights: currentProduct?.rendering_rules?.lightsV2 ?? [],
          envMapMeta:
          currentProduct?.rendering_rules?.envMapV2 ??
          currentProduct?.rendering_rules?.envMapMeta,
          shadow: {
            enabled: true,
            intensity: currentProduct?.rendering_rules?.shadowIntensity,
          },
        },
        disableModelEntryAnimation: false,
        onProgress: (progress: any) => {
          setSceneLoadingProgress(progress);
          // onProgress && onProgress(progress);
        },
        onError: (errorMessage: string) => {
          console.log("onload 3d model failed: ", errorMessage)
        },
        onModelRendered: async () => {
          // setIs3dSceneLoading(false);
          if (Object.keys(currentProduct?.rules).length > 0) {
            const parsedRules = JSON.parse(
              currentProduct?.rules.json
            ).rules;
            const PostRenderRules = parsedRules.filter(
              (rule: any) => rule.type === "PostRender"
            );
            await postRenderRules(PostRenderRules);
          }
          concatRules();
        },
      });
    }
  }

  useEffect(() => {
    setIsPostRenderingDone(false);
    setIs3dSceneLoading(true);
    fetchProduct(productId)
      .then((res): any => {
        setProduct(res);
        setup3dViewer(res)
      })
      .catch((err: any) => {
        console.error(err);
      });
  }, [productId]);

  return (
    <div
    style={{ width: "100%", height: "100%" }}
    className="flex-grow items-center w-full p-4 mx-auto h-full relative flex align-center justify-center"
  >
    {/* Show loader for scene loading */}
    {is3dSceneLoading && !isPostRenderingDone && (
      <div className="loaderContainer">
        <div className="loaderContainingWrapper">
          <div className="loaderWrapper">
            <div className="loaderOutline">
              <div
                className="loaderProgress"
                style={{ width: `${sceneLoadingProgress}%` }}
              />
              <span className="loaderText">{sceneLoadingProgress}%</span>
            </div>
          </div>
        </div>
      </div>
    )}

    {/* Main 3D Viewer */}
    <div
      id="3d-viewer"
      style={{
        display: !isPostRenderingDone ? "none" : "flex",
      }}
      className=" h-full w-full rounded text-gray-600 flex items-center justify-center"
    >
      {product && (
        <Viewer3D
          data={product}
          ref={productViewerRef}
          onSceneLoaded={() => {
            setup3dViewer(product);
          }}
          server={process.env.NODE_ENV === "production" ? "PROD" : "DEV"}
          disableSpin={true}
          disableSpec={true}
        />
      )}
    </div>

    {/* Logo */}
    <img
      src={logo}
      alt="logo"
      className="mr-auto  w-62 h-auto ml-4 absolute bottom-40 right-4"
    />
  </div>
  );
}
