import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import Configurator from "./Pages/Configurator";
import Login from "./Pages/Login";
import "./App.css";
import Dashboard from "./Pages/Dashboard";
import { useAppDispatch, useAppSelector } from "./hooks/hooks";
import { userConstants } from "./store/actions/types";
import { Toaster } from "react-hot-toast";
import { PrivateRoute } from "./Components/PrivateRoute";
import { Report } from "./Pages/Report";
import Settings from "./Pages/Settings";
import { uuid } from "./utils/uuid";
import ShippingDoc from "./Pages/ShippingDoc";

export default function App() {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  useEffect(() => {
    localStorage.setItem("sessionId", uuid());
  }, []);

  useEffect(() => {
    //  check local storage to see if the user has already logged in
    const userData = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (userData) {
      const user = JSON.parse(userData);
      if (user) {
        dispatch({
          type: userConstants.SET_USER,
          payload: { ...user, token: token },
        });
      }
    }
  }, [dispatch, history]);

  return (
    <Router>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "#a7f3d0",
              color: "#059669",
            },
          },
          error: {
            style: {
              background: "#fecaca",
              color: "#dc2626",
            },
          },
        }}
      />
      <div id="Modal"></div>
      <div id="QuotationModal"></div>
      <div id="PricingModal"></div>
      <div id="ProfileModal"></div>
      <Switch>
        <PrivateRoute path="/configurator/:productId">
          <Configurator />
        </PrivateRoute>
        <PrivateRoute path="/configurator">
          <Configurator />
        </PrivateRoute>
        <PrivateRoute path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/settings">
          <Settings />
        </PrivateRoute>
        <PrivateRoute path="/report">
          <Report />
        </PrivateRoute>
        <Route path={"/login"}>
          {isAuthenticated ? <Redirect to="/dashboard" /> : <Login />}
        </Route>
        <Route path="/shipping-doc">
          <ShippingDoc />
        </Route>
        <Route path={"/"}>
          {isAuthenticated ? <Redirect to="/dashboard" /> : <Login />}
        </Route>
      </Switch>
    </Router>
  );
}
