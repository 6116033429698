import axios from "axios";
import { Order, User } from "../types/types";
import { uuid } from "./uuid";
import { getDetails } from "./getBankDetails";
import { patchOrder } from "./ordersApi";
import toast from "react-hot-toast";
import { getUserProfileDetails } from "./userProfile";

const uploadQuoteToS3 = async (fileName: string, binaryData: any) => {
  const uploadName = fileName.split(" ").join("") + uuid();

  const applicationId = process.env.REACT_APP_APPLICATION_ID;

  const b = new Blob([binaryData], { type: "application/xlsx" });
  const file = new File([b], `${uploadName}.xlsx`, {
    type: "application/xlsx",
  });
  const authToken = localStorage.getItem("token");

  const configGetS3Url = {
    url: `${process.env.REACT_APP_HELLOAR_API}signed_file`,
    params: {
      file_name: `${uploadName}.xlsx`,
      file_type: "application/xlsx",
      application_id: applicationId,
    },
    headers: { Authorization: authToken },
  };
  try {
    const response = await axios.request(configGetS3Url);
    let formData = new FormData();
    let key;
    for (key in response.data.data.fields) {
      formData.append(key, response.data.data.fields[key]);
    }
    formData.append("file", file);

    await axios.post(response.data.data.url, formData);
    return response.data.uploaded_url;
  } catch (err) {
    throw new Error("Error while uploading content" + JSON.stringify(err));
  }
};

export const generateQuote = async (
  order: Order,
  discountPercentage: number,
  fDPercentage: number,
  advancePay: number,
  edtimatedDelivery: string
) => {
  toast.loading("Do not refresh the page while the quote is being generated", {
    duration: 5000,
  });
  const userDetails = await getUserProfileDetails();
  const {
    address,
    bank_details,
    gst_number,
  }: { address: string; bank_details: any; gst_number: string } =
    await getDetails();
  const user = localStorage.getItem("user");
  const userData: User = user && JSON.parse(user);

  const cartDataPromises = order.cart_items.map(async (item, index) => {
    return {
      "SI.No": index + 1,
      Description: item.product,
      NoOfSeats: item.noOfSeats ?? 1,
      "Reference Image": item.thumbnail, // Use URL directly
      Quantity: item.quantity,
      Unit: "Nos",
      "Special Unit Price": item.cost,
      Price: item.quantity * item.cost!,
      Specifications: item.quote,
    };
  });

  // Wait for all cartData promises to resolve
  const cartData = await Promise.all(cartDataPromises);

  const d = new Date();
  const gst = 18 / 100;
  const orderTotal = order.cart_items.reduce(
    (acc, curr) => acc + (curr.cost! * curr.quantity || 0),
    0
  );
  const discountedPrice: number = Number(
    (orderTotal * (discountPercentage / 100)).toFixed(2)
  );
  const freightAndDamage: number = Number(
    ((orderTotal - discountedPrice) * (fDPercentage / 100)).toFixed(2)
  );
  const amountPayable: number = orderTotal - discountedPrice + freightAndDamage;

  const gstAmount = (amountPayable * gst).toFixed(2);
  const amountPayableWithGst: number = Number(
    (amountPayable + amountPayable * gst).toFixed(2)
  );

  const quotationData = {
    template_file_id: "1s9QMPn6J9i3UlEsjnvJHI96sgnl-qNCvCkX_ES84xms",
    type: "sheets",
    optional_parameters: {
      Date: d.toLocaleDateString(),
      Client_Name: order.custom_data.company_name,
      Client_Location: order.customer.location,
      Salesforce_Id: order.lead_id,
      Address: address,
      DELIVERY_TIME: edtimatedDelivery,
      QUOTE_VALIDITY: "60 days",
      ORDER_TOTAL: `${orderTotal - discountedPrice}`,
      ADVANCE_PERCENTAGE: `${advancePay}%`,
      BANK_NAME: bank_details.bank_name,
      BANK_BRANCH: bank_details.branch,
      BENEFICIARY_NAME: bank_details.beneficiary_name,
      ACCOUNT_TYPE: bank_details.account_type,
      ACCOUNT_NUMBER: bank_details.account_number,
      IFSC: bank_details.ifsc,
      SWIFTCODE: bank_details.swift_code,
      MICRCODE: bank_details.micr,
      QUOTE_GENERATOR_NAME: `${userData.first_name} ${userData.last_name}`,
      DISCOUNT_PERCENTAGE: `${discountPercentage}%`,
      FREIGHT_PERCENTAGE: `${fDPercentage}%`,
      DISCOUNT_AMOUNT: `${discountedPrice}`,
      FREIGHT_AMOUNT: `${freightAndDamage}`,
      GST_AMOUNT: gstAmount.toString(),
      GRAND_TOTAL: `${amountPayableWithGst}`,
      GST_NUMBER: gst_number ? gst_number : "",
      DESIGNATION: userDetails?.custom_data?.designation || "",
    },
    order: [...cartData],
  };

  try {
    console.error(quotationData, "quotationdata ");
    const res = await axios.post(
      "https://docgenerator-n3drlt7x5a-el.a.run.app/generate-invoice-embedded-image",
      quotationData,
      {
        responseType: "arraybuffer",
      }
    );

    const quoteUrl: string = await uploadQuoteToS3(
      order.customer.name,
      res.data
    );

    const updatedOrder: Order = {
      ...order,
      documents: {
        xls: quoteUrl,
      },
    };
    await patchOrder(updatedOrder);
    return updatedOrder;
  } catch (error) {
    window.alert("Unable to generate quotation.");
  }
};
