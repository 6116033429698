import React, { useEffect, useState } from "react";
import rightArrow from "../assets/icons/right-arrow.svg";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
// import downloadIcon from "../assets/icons/download.png";
import screenshot from "../assets/icons/screenshot1.png";
import loading from "../assets/icons/loading.png";
import dropDownIcon from "../assets/icons/drop-down.svg";
import { uuid } from "../utils/uuid";
import Modal from "../Components/Modal";
import Table from "../Components/BOMTable";
import { useHistory, useParams } from "react-router-dom";
import { Order, OrderItem, Product } from "../types/types";
import { ordersConstants } from "../store/actions/types";
import toast from "react-hot-toast";
import { patchOrder } from "../utils/ordersApi";
import ConfirmAction from "../utils/confirmAction";
import { getCostApiConfiguration } from "../utils/serializeConfigData";
import { HelloARProduct, RulesEntity } from "../types/helloARProduct";
import { makeSessionRequest } from "../utils/makeSessionRequest";
import getDescription from "../utils/getDescription";
import { getIsSuperAdmin } from "../utils/getIsSuperAdmin";
import { uploadFileToS3 } from "../utils/uploader";
import axios from "axios";
import { uploadContentNew } from "../utils/apiManager";
import Spinner from "../Components/_spinner/Spinner";
// import temp from "../const/tempPdfRules.json"

type UserConfiguration = {
  [key: string]: any; // Define specific types if possible
};

type Result = {
  Category: string;
  [key: string]: any; // Define specific properties as needed
};

export default function Header({
  productViewerRef,
}: {
  productViewerRef: React.MutableRefObject<any>;
}) {
  let { productId } = useParams<{ productId: string | undefined }>();
  const [price, setPrice] = useState<number | undefined>();
  const [pricePerSeat, setPricePerSeat] = useState<number | undefined>();
  const [Bom, setBom] = useState<Product["bom"] | any>();
  const [quote, setQuote] = useState<any>("");
  const [showBom, setShowBom] = useState(false);
  const [screenshotPopup, setScreenshotPopup] = useState(false);
  const [screenshotLoading, setScreenshotLoading] = useState(false);
  const [screenshotTaken, setScreenshotTaken] = useState("");
  const [isUpdatingPrice, setIsUpdatingPrice] = useState(false);
  const [tableDimensions, setTableDimensions] = useState<
    undefined | { length: number | undefined; width: number | undefined }
  >(undefined);
  const [isUploadingGlb, setIsUploadingGlb] = useState(false);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isPriceHidden, setIsPriceHidden] = useState(true);

  const currentOrder: Order = useAppSelector(
    (state) => state.orders.currentOrder
  );
  const productt: any = useAppSelector((state) => state.productInfo.product);
  const productIDD: any = useAppSelector(
    (state) => state.productInfo.productid
  );
  const tableType = useAppSelector((state) => state.tableType.table);
  // const tableName = useAppSelector((state) => state.tableInfo.info);

  const userConfiguration2 = useAppSelector(
    (state) => state.configuration.userConfiguration2
  );

  let controller: AbortController

  const user = useAppSelector((state) => state.user);
  const pricingApi = process.env.REACT_APP_HELLOAR_PRICING;

  if (currentOrder === null) {
    history.push("/dashboard");
  }

  useEffect(() => {
  }, [userConfiguration2]);

  const getQuote = async (components: any) => {

    // Extract the description from each component
    const descriptions = components.map(
      (component: any) => component.description
    );
    const quote = descriptions.join("#########");

    setQuote(quote);
  };

  useEffect(() => {
    // if (pricingApi) {

    if (controller) {
      controller.abort();
    }
    setIsUpdatingPrice(true)
    controller = new AbortController();
    const { signal } = controller;
    const serializedData = getCostApiConfiguration(userConfiguration2);

    // if (tableType === "Workstation" || tableType === "Desking WKS") {
    const userConfigurationFiltered = Object.entries(userConfiguration2)
      .filter(
        ([key, value]) => value !== null && value !== "None" && value !== "none"
      )
      .reduce((acc: any, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    // const result = {
    //   ...userConfigurationFiltered,
    // };
    
    // Main Logic
    const result: Result = {
      ...userConfigurationFiltered,
      Category: tableType ?? "",
      ...(tableType === "Linear Workstation" &&
      userConfiguration2["Sharing Legs"] && {
        "Mid Legs": "Intermediate Leg with Cover",
        Beams: "CONNECTING BAR",
        "T Legs": "Intermediate T Leg",
      }),
      ...(tableType === "Linear Workstation" &&
      userConfiguration2["Non Sharing Legs"] && {
        "Mid Legs": "Intermediate Leg with Cover",
        Beams: "CONNECTING BAR",
        "T Legs": "Intermediate T Leg",
      }),
      ...(tableType === "Linear Workstation"
        ? {
            ...getRacewaySharing(userConfiguration2),
            ...getRacewayNonSharing(userConfiguration2),
            ...getWireManagement(userConfiguration2),
          }
        : {}),
    };

    axios
      .post(pricingApi + "price_officemate_new", result, {
        headers: {
          "Content-Type": "application/json",
        },
        signal:signal,
      })
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        setPrice(data.totalPrice);
        setPricePerSeat(
          Math.round(
            parseFloat(data.totalPrice) / (serializedData.NumberOfSeats || 1)
          )
        );
        setBom(data.components);
        getQuote(data.components);
        setIsUpdatingPrice(false)
        toast.dismiss() 
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Previous request canceled');
        } else {
          console.error('Error fetching search results:', error);
          setIsUpdatingPrice(false)
          setPrice(undefined);
          setBom(undefined);
          setQuote(undefined);
        }
      });
      return () => {
        controller?.abort();
      };
  }, [
    tableType,
    userConfiguration2,
    tableDimensions?.length,
    // pricingApi
  ]);


  function getRacewaySharing(userConfig: UserConfiguration): Partial<Result> {
    const racewaySharing = userConfig["Raceway Sharing"];
    if (racewaySharing === "Gromet with vertical raceway") {
      return {
        "Raceway Sharing": "Grommet",
        "Vertical Raceway": "Vertical Raceway",
      };
    } else if (racewaySharing === "Gromet With faceplate Cutting") {
      return {
        "Raceway Sharing": "Grommet",
        "Horizontal raceway Sharing":
          "Horizontal raceway sharing with Faceplate Cutting",
      };
    } else if (racewaySharing === "Flip Up") {
      return {
        "Horizontal raceway Sharing":
          "Horizontal Raceway Sharing with switch socket",
      };
    }
    return {};
  }
  
  function getRacewayNonSharing(userConfig: UserConfiguration): Partial<Result> {
    const racewayNonSharing = userConfig["Raceway Non Sharing"];
    if (racewayNonSharing === "Gromet with vertical raceway") {
      return {
        "Raceway Non Sharing": "Grommet",
        "Vertical Raceway": "Vertical Raceway",
      };
    } else if (racewayNonSharing === "Gromet With faceplate Cutting") {
      return {
        "Raceway Non Sharing": "Grommet",
        "Horizontal raceway Non-sharing":
          "Horizontal raceway Non-sharing with Faceplate Cutting",
      };
    } else if (racewayNonSharing === "Flip Up") {
      return {
        "Horizontal raceway Non-sharing":
          "Horizontal Raceway Non Sharing with switch socket",
      };
    }
    return {};
  }
  
  function getWireManagement(userConfig: UserConfiguration): Partial<Result> {
    const result: Partial<Result> = {};
    if (
      userConfig["Raceway Sharing"] !== "Flip Up" &&
      userConfig["Wire Management's Dimensions Sharing"]
    ) {
      result["Wire Management's Dimensions Sharing"] = "None";
    }
    if (
      userConfig["Raceway Non Sharing"] !== "Flip Up" &&
      userConfig["Wire Management's Dimensions Non Sharing"]
    ) {
      result["Wire Management's Dimensions Non Sharing"] = "None";
    }
    return result;
  }

  const getName = () => {
    function toTitleCase(str: string) {
      return str.replace(/\w\S*/g, function (txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
    const { type, Legs, noOfSeats, name } = userConfiguration2;
    const newType =
      type!.split(" ")[0] === "NON" ? "Non Sharing" : type!.split(" ")[0];
    if (type === "SHARING" || type === "NON SHARING") {
      return (
        toTitleCase(newType!) +
        " " +
        noOfSeats +
        " Seater table - " +
        (Legs || "")
      );
    } else if (type?.includes("partition")) {
      return `${noOfSeats} Seater - ${type}`;
    } else {
      return (
        toTitleCase(name!) + " " + toTitleCase(type!) + " - " + (Legs || "")
      );
    }
  };

  // const generatePDF = async () => {
  //   const ruleList = [
  //     "Front View Dimensions", "Front View", "take_screenshot", "Hide Front View Dimensions", "Top View Dimensions", "Top View", "take_screenshot", "Hide Top View Dimensions", "Side View Dimensions", "Side View", "take_screenshot", "Hide Side View Dimensions"
  //   ]
  //   const runCustomRule = async (ruleName: string) => {
  //     return new Promise(async (resolve, reject) => {
  //       if (temp?.rules && temp.rules.length > 0) {
  //         const ruleToRun = temp.rules.find((rule) =>
  //           rule.iconText.toLowerCase() === ruleName.toLowerCase())

  //         await productViewerRef.current?.viewer?.runCustomRule(
  //           ruleToRun,
  //           () => resolve(true),
  //           () => reject(false)
  //         );
  //       } else {
  //         return reject("camera reset rule not found");
  //       }
  //     });
  //   };
  //   const getAllScreenshots = async () => {
  //     let screenshots = []
  //     for (let i = 0; i < ruleList.length; i++) {
  //       if (ruleList[i] !== "take_screenshot") {

  //         await runCustomRule(ruleList[i])
  //       } else {
  //         const thumbnailUrl: string = await productViewerRef.current?.viewer?.saveAsImage(
  //           "featherlite-customizer-for-web/thumbnails/" + uuid()
  //         );
  //         const ruleName: string = ruleList[i - 1]
  //         screenshots.push({
  //           view: ruleName, thumbnailUrl
  //         })

  //       }
  //     }
  //     return screenshots
  //   }
  //   const screenshots = await getAllScreenshots()

  // }

  // const getFullName = () => {
  //   const { type, Legs, Laminate, Length, Depth, Dia, noOfSeats, table } =
  //     userConfiguration2;
  //   const sideTable = table
  //     ? table?.split("-")[1].replace("x", "Wx") + "D"
  //     : undefined;
  //   const configArray = [
  //     type,
  //     type === "SHARING" || type === "NON SHARING"
  //       ? `${noOfSeats} Seater`
  //       : undefined,
  //     Legs !== undefined ? `${Legs} legs` : Legs,
  //     Laminate,
  //     Length && tableDimensions?.length !== undefined
  //       ? `${Length || tableDimensions?.length}W`
  //       : undefined,
  //     Depth && tableDimensions?.width !== undefined
  //       ? `${Depth || tableDimensions?.width}D`
  //       : undefined,
  //     sideTable,
  //     Dia !== undefined ? `${Dia}Dia` : undefined,
  //   ];

  //   return configArray
  //     .filter((val) => {
  //       return val !== undefined;
  //     })
  //     .join(" - ");
  // };

  const saveAsImage = async () => {
    setScreenshotLoading(true);
    const imgUrl = await productViewerRef.current?.getCore3D()?.saveAsImage();
    setScreenshotTaken(imgUrl);
    setScreenshotLoading(false);
    setScreenshotPopup(true);
  };

  const downloadImage = (base64Data: string, filename: string) => {
    // Create a link element
    const link = document.createElement("a");

    // Set the href attribute to the base64 data
    link.href = base64Data;

    // Set the download attribute to the filename
    link.download = filename;

    // Create a click event
    const clickEvent = new MouseEvent("click");

    // Dispatch the click event on the link
    link.dispatchEvent(clickEvent);
  };

  const resetCamera = async () => {
    return new Promise(async (resolve, reject) => {
      const rules: RulesEntity[] = JSON.parse(productt?.value.rules.json).rules;

      const cameraResetRule = rules.find((rule) => {
        return rule.actions?.find((action) => action.type === "MoveCamera");
      });

      productViewerRef.current?.runCustomRule(
        { rule: cameraResetRule, node: [] },
        () => {}
      );
    });
  };

  const onCloseModal = () => {
    setShowBom(false);
  };

  const getGlbOfCurrentScene = async () => {
    const glbfile = await productViewerRef.current
      ?.getCore3D()
      ?.getModelManager()
      ?.getModel();
    return await glbfile;
  };

  // Convert Base64 string to Blob
  function base64ToBlob(base64: any, mime: any) {
    const byteString = atob(base64.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mime });
  }

  const addProductToOrder = async () => {
    if (isUpdatingPrice) {
      toast("Updating the price. Please try again later.")
      return
    }
    setIsUploadingGlb(true);
    // await resetCamera();
    const thumbnailBase64 = await productViewerRef.current
      ?.getCore3D()
      ?.saveAsImage();

    // if (thumbnailBase64) {
    const mimeType = "image/png";
    const thumbnailBlob = base64ToBlob(thumbnailBase64, mimeType);
    const thumbnailUrl = await uploadContentNew(
      "thumbnails/" + uuid() + ".png",
      mimeType,
      thumbnailBlob
    );
    // }

    // if (!Bom) {
    //   toast.error("Could not fetch BOM");
    //   return;
    // }
    const id = productIDD?.value; //todo get product id
    let glbUrl = "";
    try {
      const glbFile: File = await getGlbOfCurrentScene();
      

      glbUrl = await uploadFileToS3(
        "customized_model.glb",
        "application/octet-stream",
        glbFile
      );
    } catch (err) {
      console.error("hi", err);
      setIsUploadingGlb(false);
      toast.error("Could not save 3D scene");
      return
    }

    const newProduct: OrderItem = {
      product: getDescription(userConfiguration2, tableType),
      noOfSeats: userConfiguration2["noOfSeats"] ?? 1,
      product_id: id,
      glb: glbUrl,
      bom: {}, //Bom,
      configuration: {
        ...userConfiguration2,
        name: getName(),
        Category: tableType ?? "",
      },
      thumbnail: thumbnailUrl,
      quantity: 1,
      cost: price,
      quote: quote || " ",
    };

    const orderCopy = currentOrder;
    if (productId) {
      const currentProduct = currentOrder.cart_items[parseInt(productId)];
      newProduct.quantity = currentProduct.quantity || 1;
      newProduct.cost = price;
      orderCopy.cart_items[parseInt(productId)] = newProduct;
    } else {
      orderCopy.cart_items = [newProduct, ...orderCopy.cart_items];
    }

    try {
      const data = await patchOrder(orderCopy);
      dispatch({
        type: ordersConstants.UPDATE_ORDER,
        payload: {
          ...orderCopy,
          created_at: data.created_at,
          updated_at: data.updated_at,
        },
      });
      toast.success(productId ? "Product updated" : "Product added to order");
      makeSessionRequest(productId ? "product_updated" : "product_added");
      window.location.replace("/dashboard");
    } catch (error) {
      makeSessionRequest(
        productId ? "product_update_failed" : "failed_to_add_product",
        { error: error }
      );
      setIsUploadingGlb(false);
      toast.error("Failed to add product to order");
      return
    }
  };

  return (
    <div className={`fixed w-full top-5 right-0 pr-8 z-20 transition-all `}>
      {showBom && (
         <Modal
            show={showBom}
            onClose={() => {
              onCloseModal();
            }}
          >
            <Table bom={Bom} />
        </Modal>
        
      )}
      {isUploadingGlb && (
        <Modal show={isUploadingGlb} onClose={() => {}}>
          <div
            style={{
              height: 250,
              width: 250,
              display: "flex",
              padding: 8,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Spinner text={`${productId ? "Updating" : "Adding"} product`} />
          </div>
        </Modal>
      )}
      {screenshotPopup && (
        <Modal
          show={screenshotPopup}
          onClose={() => {
            setScreenshotPopup(false);
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              maxWidth: "700px",
              display: "flex",
              flexDirection: "column",
              padding: 8,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              // border:"2px solid blue"
            }}
          >
            <div>
              <img src={screenshotTaken} alt=""></img>
            </div>
            <div>
              <button
                onClick={() => {
                  downloadImage(screenshotTaken, "screenshot");
                }}
                className="tracking-widest text-xs 2xl:text-base ml-2 px-8 py-3 hover:bg-black bg-customBlack-light text-white rounded-full font-bold"
              >
                Download
              </button>
            </div>
          </div>
        </Modal>
      )}
      <div className=" w-full  items-center  px-5 flex">
        <div
          onClick={() => {
            if (Object.keys(userConfiguration2).length >= 3) {
              ConfirmAction("Are you sure? You have unsaved data.", "", () => {
                history.push("/dashboard");
                makeSessionRequest("back_to_dashboard");
              });
            } else {
              history.push("/dashboard");
              makeSessionRequest("back_to_dashboard");
            }
          }}
          className="flex ml-4 items-center cursor-pointer w-max mr-auto"
        >
          <img src={rightArrow} alt="back" className="mr-3 h-5" />
          <h3 className="text-lg xl:text-2xl font-bold">Quotations</h3>
        </div>

        <h2 className="text-sm 2xl:text-lg font-bold justify-items-end pl-4 ">
          {getName()}
        </h2>
        <div className="bg-gray-600 w-px h-8 mx-4"></div>

        {!isPriceHidden && (
          <div className="transition-all">
            <div
              onClick={() => {
                // const isSuperAdmin: boolean = getIsSuperAdmin();
                // if (isSuperAdmin) {
                if (!isUpdatingPrice) {
                  setShowBom(true);
                } else {
                  toast("Updating the price. Please check once it has been updated.")
                }
                // }
              }}
              className={`${
                user.role === 3 || user.role === undefined
                  ? "cursor-pointer" //"pointer-events-none"
                  : "cursor-pointer"
              } flex-col mr-4`}
            >
              <h2 className=" text-base 2xl:text-lg  font-bold">
                {(price && !isUpdatingPrice && price) || " ---"} ₹
              </h2>

              {(tableType === "Linear Workstation" ||
                tableType === "Desking WKS") && (
                <h2 className=" text-xs 2xl:text-base  font-bold">
                  {((pricePerSeat && !isUpdatingPrice && pricePerSeat) || "---") + " ₹ per seat"}
                </h2>
              )}

              <h2 className="text-xs 2xl:text-base text-gray-500">
                Taxes are applicable
              </h2>
            </div>
          </div>
        )}
        <span
          className="flex cursor-pointer"
          onClick={() => {
            setIsPriceHidden(!isPriceHidden);
            makeSessionRequest(
              isPriceHidden ? "clicked_show_price" : "clicked_hide_price"
            );
          }}
        >
          {isPriceHidden && "Show Price"}
          <img
            className={`transform transition-transform px-4 ${
              isPriceHidden ? "" : "rotate-180"
            } `}
            src={dropDownIcon}
            alt=""
          />
        </span>
        <div
          onClick={() => {
            makeSessionRequest("clicked_screenshot");
            saveAsImage();
          }}
          className="cursor-pointer ml-12 shadow bg-white px-3 py-3 rounded-full"
        >
          {screenshotLoading ? (
            <img src={loading} alt="Loading..." className="mb-0 h-5 rotating" />
          ) : (
            <img src={screenshot} alt="Screenshot" className="mb-0 h-6 " />
          )}

          {/* <img src={fullScreenIcon} alt="" className="h-6 cursor-pointer" />  */}
        </div>
        <button
          onClick={() => {
            addProductToOrder();
            makeSessionRequest(
              productId ? "clicked_update_product" : "clicked_add_product"
            );
          }}
          className="tracking-widest text-xs 2xl:text-base ml-2 px-8 py-3 hover:bg-black bg-customBlack-light text-white rounded-full font-bold"
        >
          {productId ? "UPDATE PRODUCT" : "ADD PRODUCT"}
        </button>
        {/* <button
          onClick={generatePDF}
          className="tracking-widest text-xs 2xl:text-base ml-2 px-8 py-3 hover:bg-black bg-customBlack-light text-white rounded-full font-bold"
        >
          Generate Pdf
        </button> */}
      </div>
    </div>
  );
}
