import { features } from "process";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Sharing",
        required: false,
        options: [
          {
            name: "Sharing Table",
            "Table Top Options": "Plain",
            size: 2,
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/hat/sharing.png",
            productId: "66584032ba391204b8529839",
            tableTop: ["table_top"],
            Category: "Height Adjustable Tables",
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            disableOptions: ["Grommet", "Mid Metal", "1050 mm", "1800 mm"],
            disableFeatures: [
              "Aluminium Panel Options",
              "Fabric Panel Accessories",
              "Privacy Panel Height(S)",
            ],
            enableOptions: ["Pole", "Fixed Panel"],
          },
        ],
      },
      {
        name: "Non Sharing",
        required: false,
        options: [
          {
            name: "Non Sharing Table",
            "Table Top Options": "Plain",
            size: 1,
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/hat/nonsharing.png",
            productId: "66581f7ebef76f63cfb7ef8d",
            tableTop: ["table_top"],
            Category: "Height Adjustable Tables",
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            disableOptions: ["Pole", "1050 mm", "Fixed Panel"],
            enableFeatures:["Mid Modesty",],
            enableOptions: ["Grommet", "Mid Metal", "1650 mm", "1800 mm"],
            disableFeatures: [
              "Aluminium Panel Options",
              "Fabric Panel Accessories",
              "Privacy Panel Height(S)",
            ],
          
          },
        ],
      },
      // {
      //   name: "120 Degree",
      //   required: false,
      //   options: [
      //     {
      //       name: "120 Degree",
      //       size: 1050,
      //       Length: 1200,
      //       Height: 600,
      //       thumbnail: "assets/system/placeholder.svg",
      //       productId: "66587e5c71dc412bc38a250b",
      //       tableTop: ["table_top"],
      //       Category: "Height Adjustable Tables",
      //       leftLegs: ["Leg"],
      //       rightLegs: ["Leg"],
      //       disableOptions: [
      //         "Pole",
      //         "Grommet",
      //         "Mid Metal",
      //         "1650 mm",
      //         "1800 mm",
      //         "Fixed Panel",
      //       ],
      //       disableFeatures: [
      //         "Aluminium Panel Options",
      //         "Fabric Panel Accessories",
      //       ],
      //       enableOptions: ["Flip-Up", "1050 mm"],
      //     },
      //   ],
      // },
    ],
  },

  {
    name: "Dimensions",
    multiSelect: false,
    options: [
      {
        name: "Sharing",
        required: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },

              {
                name: "700 mm",
                value: 700,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing",
        required: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },

              {
                name: "700 mm",
                value: 700,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
            ],
          },
        ],
      },
      {
        name: "120 Degree",
        required: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },

              {
                name: "700 mm",
                value: 700,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
            ],
          },
        ],
      },
    ],
  },
{
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    options: [
      {
        name: "Sharing",
        required: false,
        options: [
          {
            name: "Wire Management",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: ["gorment", "FlipUp"],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: show,
                    node: ["FlipUp"],
                  },
                  {
                    type: hide,
                    node: ["gorment", "Table_top"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing",
        required: false,
        options: [
          {
            name: "Wire Management",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: ["gorment", "FlipUp"],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: show,
                    node: ["FlipUp"],
                  },
                  {
                    type: hide,
                    node: ["gorment", "Table_top"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Degree",
        required: false,
        options: [
          {
            name: "Wire Management",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: ["gorment", "FlipUp"],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },

              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: show,
                    node: ["FlipUp"],
                  },
                  {
                    type: hide,
                    node: ["gorment", "Table_top"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
    

  {
    name: "Privacy",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Sharing",
        required: false,
        options: [
          // {
          //   name: "Mid Modesty",
          //   nodeName: [
          //     "prelammid",
          //     "metalmodestymid",
          //     "metalmodestymid@work",
          //     "prelammid@work",
          //   ],
          //   required: false,
          //   options: [
          //     {
          //       name: "None",
          //       thumbnail: "/assets/components/none.jpg",
          //       pid: "",
          //       rules: [
          //         {
          //           type: "hide",
          //           node: [
          //             "prelammid",
          //             "metalmodestymid",
          //             "metalmodestymid@work",
          //             "prelammid@work",
          //           ],
          //         },
          //       ],
          //     },
          //     // {
          //     //   name: "Mid Prelam",
          //     //   thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
          //     //   rules: [
          //     //     {
          //     //       type: "show",
          //     //       node: ["prelammid"],
          //     //     },
          //     //     {
          //     //       type: "hide",
          //     //       node: ["metalmodestymid", "gorment"],
          //     //     },
          //     //     {
          //     //       type: "hide",
          //     //       condition: [
          //     //         {
          //     //           feature: "Legs",
          //     //           values: ["@work", "IO"],
          //     //         },
          //     //       ],
          //     //       node: ["prelammid", "metalmodestymid@work"],
          //     //     },
          //     //     {
          //     //       type: "show",
          //     //       condition: [
          //     //         {
          //     //           feature: "Legs",
          //     //           values: ["@work", "IO"],
          //     //         },
          //     //       ],
          //     //       node: ["prelammid@work"],
          //     //     },
          //     //   ],
          //     // },
          //     {
          //       name: "Mid Metal",
          //       thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
          //       rules: [
          //         {
          //           type: "show",
          //           node: ["metalmodestymid", "metalmodestymid@work"],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             { feature: "Privacy Panels", values: ["Hung Panel"] },
          //           ],
          //           node: ["metalmodestymid@work", "metalmodestymid"],
          //         },
          //       ],
          //     },
          //   ],
          // },

          {
            name: "Privacy Panels",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                enableFeatures: "Mid Modesty",
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "Privacy Panel Height(S)",
                ],

                rules: [
                  {
                    type: "hide",

                    node: [
                      "fabric",
                      "glass",
                      "aluminium",
                      "alumfabric",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "Hung_panel",
                      "alumglass",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                disableFeatures: ["Aluminium Panel Options"],
                enableFeatures: ["Fabric Panel Accessories", "Mid Modesty","Privacy Panel Height(S)",],

                disableOptions: ["300G", "300A", "450G", "450A","300H", "450H"],
                enableOptions: ["300F", "450F"],
                rules: [
                  {
                    type: "show",
                    node: ["fabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "Hung_panel",
                      ,
                      "alumglass",
                    ],
                  },
                ],
              },
              {
                name: "Glass",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                //pid: "6122f35afe408b1279847325",
                enableFeatures: ["Mid Modesty","Privacy Panel Height(S)",],
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                ],
                disableOptions: ["300F", "300A", "450F", "450A","300H", "450H"],
                enableOptions: ["300G", "450G"],
              
                rules: [
                  {
                    type: "show",
                    node: ["glass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "Hung_panel",
                      ,
                      "alumglass",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: ["300F", "300G", "450F", "450G","300H", "450H"],
                enableOptions: ["300A", "450A"],
                enableFeatures: [ "Mid Modesty","Privacy Panel Height(S)",],
                disableFeatures: ["Fabric Panel Accessories"],
                
                rules: [
                  {
                    type: "show",
                    node: ["aluminium", "alumfullfabric"],
                  },
                  {
                    type: "hide",
                    node: ["fabric", "glass", "Hung_panel"],
                  },
                ],
              },
              {
                name: "Hung Panel",
                thumbnail: "assets/system/thumbnails/Parts/hung panel.png",
                //pid: "6122f35afe408b1279847325",

                disableFeatures: [
                  "Fabric Panel Accessories",
                  "Aluminium Panel Options",
                  "Mid Modesty",
                ],
                disableOptions: ["300F", "300G", "450F", "450G","300A", "450A"],
                enableOptions: ["300H", "450H"],
                enableFeatures:["Privacy Panel Height(S)",],
                rules: [
                  {
                    type: "show",
                    node: ["Hung_panel"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "aluminium",
                      "alumfullfabric",
                      "Fixed_panel",
                    ],
                  },
                ],
              },
              // {
              //   name: "Fixed Panel",
              //   thumbnail: "assets/system/placeholder.svg",
              //   //pid: "6122f35afe408b1279847325",

              //   disableFeatures: [
              //     "Fabric Panel Accessories",
              //     "Aluminium Panel Options",
              //     "Mid Modesty",
              //   ],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["Fixed_panel"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "fabric",
              //         "glass",
              //         "aluminium",
              //         "alumfullfabric",
              //         "Hung_panel",
              //       ],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Privacy Panel Height(S)",
            multiSelect: true,
            options: [
              {
                name: "300F",
                required: false,
              },

              {
                name: "450F",
                required: false,
              },
              {
                name: "300G",
                required: false,
              },

              {
                name: "450G",
                required: false,
              },
              {
                name: "300A",
                enableFeatures: ["Aluminium Panel Options"],
                enableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                disableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                required: false,
              },

              {
                name: "450A",
                disableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                enableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                enableFeatures: ["Aluminium Panel Options"],
                required: false,
              },
              {
                name: "300H",
                required: false,
              },

              {
                name: "450H",
                required: false,
              },
            ],
          },

          {
            name: "Aluminium Panel Options",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "Full Fabric3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
              {
                name: "Full Fabric4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
            ],
          },

          {
            name: "Fabric Panel Accessories",
            nodeName: ["aluminium", "alumfabric"],
            multiSelect: true,
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                    ],
                  },
                ],
              },
              {
                name: "Accessories Rail",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["rail"],
                  },
                ],
              },
              {
                name: "Metal Nameplate",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["metal_nameplate"],
                  },
                  {
                    type: "hide",
                    node: ["Alluminiumname_board"],
                  },
                ],
              },
              {
                name: "Alluminiumname_board",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["Alluminiumname_board"],
                  },
                  {
                    type: "hide",
                    node: ["metal_nameplate"],
                  },
                ],
              },
              {
                name: "Hanging whiteboard",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["hangingwhiteboard"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing",
        required: false,
        options: [
          {
            name: "Mid Modesty",
            nodeName: [
              "prelammid",
              "metalmodestymid",
              "metalmodestymid@work",
              "prelammid@work",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelammid",
                      "metalmodestymid",
                      "metalmodestymid@work",
                      "prelammid@work",
                    ],
                  },
                ],
              },
              // {
              //   name: "Mid Prelam",
              //   thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["prelammid"],
              //     },
              //     {
              //       type: "hide",
              //       node: ["metalmodestymid", "gorment"],
              //     },
              //     {
              //       type: "hide",
              //       condition: [
              //         {
              //           feature: "Legs",
              //           values: ["@work", "IO"],
              //         },
              //       ],
              //       node: ["prelammid", "metalmodestymid@work"],
              //     },
              //     {
              //       type: "show",
              //       condition: [
              //         {
              //           feature: "Legs",
              //           values: ["@work", "IO"],
              //         },
              //       ],
              //       node: ["prelammid@work"],
              //     },
              //   ],
              // },
              {
                name: "Mid Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",
                    node: ["metalmodestymid", "metalmodestymid@work"],
                  },
                  {
                    type: hide,
                    condition: [
                      { feature: "Privacy Panels", values: ["Hung Panel"] },
                    ],
                    node: ["metalmodestymid@work", "metalmodestymid"],
                  },
                ],
              },
            ],
          },

          {
            name: "Privacy Panels",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                enableFeatures: "Mid Modesty",
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "Privacy Panel Height(S)",
                ],

                rules: [
                  {
                    type: "hide",

                    node: [
                      "fabric",
                      "glass",
                      "aluminium",
                      "alumfabric",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "Hung_panel",
                      "alumglass",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                disableFeatures: ["Aluminium Panel Options"],
                enableFeatures: ["Fabric Panel Accessories", "Mid Modesty","Privacy Panel Height(S)",],

                disableOptions: ["300G", "300A", "450G", "450A","300H", "450H"],
                enableOptions: ["300F", "450F"],
                rules: [
                  {
                    type: "show",
                    node: ["fabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "Hung_panel",
                      ,
                      "alumglass",
                    ],
                  },
                ],
              },
              {
                name: "Glass",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                //pid: "6122f35afe408b1279847325",
                enableFeatures: ["Mid Modesty","Privacy Panel Height(S)",],
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                ],
                disableOptions: ["300F", "300A", "450F", "450A","300H", "450H"],
                enableOptions: ["300G", "450G"],
              
                rules: [
                  {
                    type: "show",
                    node: ["glass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "Hung_panel",
                      ,
                      "alumglass",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: ["300F", "300G", "450F", "450G","300H", "450H"],
                enableOptions: ["300A", "450A"],
                enableFeatures: [ "Mid Modesty","Privacy Panel Height(S)",],
                disableFeatures: ["Fabric Panel Accessories"],
                
                rules: [
                  {
                    type: "show",
                    node: ["aluminium", "alumfullfabric"],
                  },
                  {
                    type: "hide",
                    node: ["fabric", "glass", "Hung_panel"],
                  },
                ],
              },
              {
                name: "Hung Panel",
                thumbnail: "assets/system/thumbnails/Parts/hung panel.png",
                //pid: "6122f35afe408b1279847325",

                disableFeatures: [
                  "Fabric Panel Accessories",
                  "Aluminium Panel Options",
                  "Mid Modesty",
                ],
                disableOptions: ["300F", "300G", "450F", "450G","300A", "450A"],
                enableOptions: ["300H", "450H"],
                enableFeatures:["Privacy Panel Height(S)",],
                rules: [
                  {
                    type: "show",
                    node: ["Hung_panel"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "aluminium",
                      "alumfullfabric",
                      "Fixed_panel",
                    ],
                  },
                ],
              },
              // {
              //   name: "Fixed Panel",
              //   thumbnail: "assets/system/placeholder.svg",
              //   //pid: "6122f35afe408b1279847325",

              //   disableFeatures: [
              //     "Fabric Panel Accessories",
              //     "Aluminium Panel Options",
              //     "Mid Modesty",
              //   ],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["Fixed_panel"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "fabric",
              //         "glass",
              //         "aluminium",
              //         "alumfullfabric",
              //         "Hung_panel",
              //       ],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Privacy Panel Height(S)",
            multiSelect: true,
            options: [
              {
                name: "300F",
                required: false,
              },

              {
                name: "450F",
                required: false,
              },
              {
                name: "300G",
                required: false,
              },

              {
                name: "450G",
                required: false,
              },
              {
                name: "300A",
                enableFeatures: ["Aluminium Panel Options"],
                enableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                disableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                required: false,
              },

              {
                name: "450A",
                disableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                enableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                enableFeatures: ["Aluminium Panel Options"],
                required: false,
              },
              {
                name: "300H",
                required: false,
              },

              {
                name: "450H",
                required: false,
              },
            ],
          },

          {
            name: "Aluminium Panel Options",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "Full Fabric3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
              {
                name: "Full Fabric4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
            ],
          },

          {
            name: "Fabric Panel Accessories",
            nodeName: ["aluminium", "alumfabric"],
            multiSelect: true,
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                    ],
                  },
                ],
              },
              {
                name: "Accessories Rail",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["rail"],
                  },
                ],
              },
              {
                name: "Metal Nameplate",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["metal_nameplate"],
                  },
                  {
                    type: "hide",
                    node: ["Alluminiumname_board"],
                  },
                ],
              },
              {
                name: "Alluminiumname_board",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["Alluminiumname_board"],
                  },
                  {
                    type: "hide",
                    node: ["metal_nameplate"],
                  },
                ],
              },
              {
                name: "Hanging whiteboard",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["hangingwhiteboard"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "120 Degree",
        required: false,
        options: [
          {
            name: "Mid Modesty",
            nodeName: [
              "prelammid",
              "metalmodestymid",
              "metalmodestymid@work",
              "prelammid@work",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelammid",
                      "metalmodestymid",
                      "metalmodestymid@work",
                      "prelammid@work",
                    ],
                  },
                ],
              },
              // {
              //   name: "Mid Prelam",
              //   thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["prelammid"],
              //     },
              //     {
              //       type: "hide",
              //       node: ["metalmodestymid", "gorment"],
              //     },
              //     {
              //       type: "hide",
              //       condition: [
              //         {
              //           feature: "Legs",
              //           values: ["@work", "IO"],
              //         },
              //       ],
              //       node: ["prelammid", "metalmodestymid@work"],
              //     },
              //     {
              //       type: "show",
              //       condition: [
              //         {
              //           feature: "Legs",
              //           values: ["@work", "IO"],
              //         },
              //       ],
              //       node: ["prelammid@work"],
              //     },
              //   ],
              // },
              {
                name: "Mid Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",
                    node: ["metalmodestymid", "metalmodestymid@work"],
                  },
                  {
                    type: hide,
                    condition: [
                      { feature: "Privacy Panels", values: ["Hung Panel"] },
                    ],
                    node: ["metalmodestymid@work", "metalmodestymid"],
                  },
                ],
              },
            ],
          },

          {
            name: "Privacy Panels",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                enableFeatures: "Mid Modesty",
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                ],
                rules: [
                  {
                    type: "hide",

                    node: [
                      "fabric",
                      "glass",
                      "aluminium",
                      "alumfabric",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "Hung_panel",
                      "alumglass",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                disableFeatures: ["Aluminium Panel Options"],
                enableFeatures: ["Fabric Panel Accessories", "Mid Modesty"],

                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["fabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "Hung_panel",
                      ,
                      "alumglass",
                    ],
                  },
                ],
              },
              {
                name: "Glass",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                //pid: "6122f35afe408b1279847325",
                enableFeatures: ["Mid Modesty"],
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["glass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "Hung_panel",
                      ,
                      "alumglass",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                enableFeatures: ["Aluminium Panel Options", "Mid Modesty"],
                disableFeatures: ["Fabric Panel Accessories"],
                rules: [
                  {
                    type: "show",
                    node: ["aluminium", "alumfullfabric"],
                  },
                  {
                    type: "hide",
                    node: ["fabric", "glass", "Hung_panel"],
                  },
                ],
              },
              {
                name: "Hung Panel",
                thumbnail: "assets/system/placeholder.svg",
                //pid: "6122f35afe408b1279847325",

                disableFeatures: [
                  "Fabric Panel Accessories",
                  "Aluminium Panel Options",
                  "Mid Modesty",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["Hung_panel"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "aluminium",
                      "alumfullfabric",
                      "Fixed_panel",
                    ],
                  },
                ],
              },
              // {
              //   name: "Fixed Panel",
              //   thumbnail: "assets/system/placeholder.svg",
              //   //pid: "6122f35afe408b1279847325",

              //   disableFeatures: [
              //     "Fabric Panel Accessories",
              //     "Aluminium Panel Options",
              //     "Mid Modesty",
              //   ],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["Fixed_panel"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "fabric",
              //         "glass",
              //         "aluminium",
              //         "alumfullfabric",
              //         "Hung_panel",
              //       ],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Aluminium Panel Options",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "Full Fabric",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf"],
                  },
                ],
              },
              {
                name: "Full Pin up",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric"],
                  },
                ],
              },
              {
                name: "Full laminate",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric"],
                  },
                ],
              },
              {
                name: "Full Glass",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric"],
                  },
                ],
              },
            ],
          },

          {
            name: "Fabric Panel Accessories",
            nodeName: ["aluminium", "alumfabric"],
            multiSelect: true,
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                    ],
                  },
                ],
              },
              {
                name: "Accessories Rail",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["rail"],
                  },
                ],
              },
              {
                name: "Metal Nameplate",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["metal_nameplate"],
                  },
                  {
                    type: "hide",
                    node: ["Alluminiumname_board"],
                  },
                ],
              },
              {
                name: "Alluminiumname_board",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["Alluminiumname_board"],
                  },
                  {
                    type: "hide",
                    node: ["metal_nameplate"],
                  },
                ],
              },
              {
                name: "Hanging whiteboard",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["hangingwhiteboard"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Sharing",
        required: false,

        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing",
        required: false,

        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "120 Degree",
        required: false,

        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Sharing",
        required: false,

        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing",
        required: false,

        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "120 Degree",
        required: false,

        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fabric Color",
    options: [
      {
        name: "Sharing",
        required: false,
        options: [
          {
            name: "Tangerine",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
              },
            ],
          },
          {
            name: "Acid",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
              },
            ],
          },
          {
            name: "Acorn",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
              },
            ],
          },
          {
            name: "Amethyst",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
              },
            ],
          },
          {
            name: "Apollo",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
              },
            ],
          },
          {
            name: "Azure",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
              },
            ],
          },

          {
            name: "Blush",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
              },
            ],
          },
          {
            name: "Brown",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
              },
            ],
          },
          {
            name: "Burnt Orange",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
              },
            ],
          },
          {
            name: "Corn Flower",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
              },
            ],
          },
          {
            name: "Magenta",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
              },
            ],
          },

          {
            name: "Pink",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
              },
            ],
          },
          {
            name: "Purple",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
              },
            ],
          },
          {
            name: "Raspberry",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
              },
            ],
          },
          {
            name: "Ruby",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
              },
            ],
          },
          {
            name: "Salmon",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
              },
            ],
          },
          {
            name: "Shimmer",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
              },
            ],
          },

          {
            name: "Electric",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
              },
            ],
          },
          {
            name: "Frengia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
              },
            ],
          },
          {
            name: "Baby Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing",
        required: false,
        options: [
          {
            name: "Tangerine",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
              },
            ],
          },
          {
            name: "Acid",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
              },
            ],
          },
          {
            name: "Acorn",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
              },
            ],
          },
          {
            name: "Amethyst",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
              },
            ],
          },
          {
            name: "Apollo",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
              },
            ],
          },
          {
            name: "Azure",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
              },
            ],
          },

          {
            name: "Blush",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
              },
            ],
          },
          {
            name: "Brown",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
              },
            ],
          },
          {
            name: "Burnt Orange",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
              },
            ],
          },
          {
            name: "Corn Flower",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
              },
            ],
          },
          {
            name: "Magenta",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
              },
            ],
          },

          {
            name: "Pink",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
              },
            ],
          },
          {
            name: "Purple",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
              },
            ],
          },
          {
            name: "Raspberry",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
              },
            ],
          },
          {
            name: "Ruby",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
              },
            ],
          },
          {
            name: "Salmon",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
              },
            ],
          },
          {
            name: "Shimmer",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
              },
            ],
          },

          {
            name: "Electric",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
              },
            ],
          },
          {
            name: "Frengia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
              },
            ],
          },
          {
            name: "Baby Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
              },
            ],
          },
        ],
      },
      {
        name: "120 Degree",
        required: false,
        options: [
          {
            name: "Tangerine",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
              },
            ],
          },
          {
            name: "Acid",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
              },
            ],
          },
          {
            name: "Acorn",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
              },
            ],
          },
          {
            name: "Amethyst",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
              },
            ],
          },
          {
            name: "Apollo",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
              },
            ],
          },
          {
            name: "Azure",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
              },
            ],
          },

          {
            name: "Blush",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
              },
            ],
          },
          {
            name: "Brown",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
              },
            ],
          },
          {
            name: "Burnt Orange",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
              },
            ],
          },
          {
            name: "Corn Flower",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
              },
            ],
          },
          {
            name: "Magenta",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
              },
            ],
          },

          {
            name: "Pink",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
              },
            ],
          },
          {
            name: "Purple",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
              },
            ],
          },
          {
            name: "Raspberry",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
              },
            ],
          },
          {
            name: "Ruby",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
              },
            ],
          },
          {
            name: "Salmon",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
              },
            ],
          },
          {
            name: "Shimmer",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
              },
            ],
          },

          {
            name: "Electric",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
              },
            ],
          },
          {
            name: "Frengia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
              },
            ],
          },
          {
            name: "Baby Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Glass Color",
    options: [
      {
        name: "Sharing",
        required: false,
        options: [
          {
            name: "SKY BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
              },
            ],
          },
          {
            name: "SOLAR YELLOW",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
              },
            ],
          },
          {
            name: "OCEAN GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
              },
            ],
          },
          {
            name: "ORANGE MIST",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
              },
            ],
          },
          {
            name: "FLAME RED",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
              },
            ],
          },
          {
            name: "CASIS PURPLE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
              },
            ],
          },
          {
            name: "AZURE BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
              },
            ],
          },
          {
            name: "APPLE GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
              },
            ],
          },
          {
            name: "SUNNY ORANGE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
              },
            ],
          },
          {
            name: "SUPER WHITE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
              },
            ],
          },
          {
            name: "GREEN LIME",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing",
        required: false,
        options: [
          {
            name: "SKY BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
              },
            ],
          },
          {
            name: "SOLAR YELLOW",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
              },
            ],
          },
          {
            name: "OCEAN GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
              },
            ],
          },
          {
            name: "ORANGE MIST",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
              },
            ],
          },
          {
            name: "FLAME RED",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
              },
            ],
          },
          {
            name: "CASIS PURPLE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
              },
            ],
          },
          {
            name: "AZURE BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
              },
            ],
          },
          {
            name: "APPLE GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
              },
            ],
          },
          {
            name: "SUNNY ORANGE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
              },
            ],
          },
          {
            name: "SUPER WHITE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
              },
            ],
          },
          {
            name: "GREEN LIME",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "120 Degree",
        required: false,
        options: [
          {
            name: "SKY BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
              },
            ],
          },
          {
            name: "SOLAR YELLOW",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
              },
            ],
          },
          {
            name: "OCEAN GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
              },
            ],
          },
          {
            name: "ORANGE MIST",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
              },
            ],
          },
          {
            name: "FLAME RED",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
              },
            ],
          },
          {
            name: "CASIS PURPLE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
              },
            ],
          },
          {
            name: "AZURE BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
              },
            ],
          },
          {
            name: "APPLE GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
              },
            ],
          },
          {
            name: "SUNNY ORANGE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
              },
            ],
          },
          {
            name: "SUPER WHITE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
              },
            ],
          },
          {
            name: "GREEN LIME",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;