import React from "react";
import "./Spinner.css";

// Define the props type, with "text" as optional
interface SpinnerProps {
  text?: string; // Optional prop
}

const Spinner: React.FC<SpinnerProps> = ({ text }) => {
  return (
    <div className="fixed z-50 w-full h-full flex flex-col justify-center items-center">
      <div className="loader"></div>
      {text && <div className="spinner-text mt-4">{text}</div>}
    </div>
  );
};

export default Spinner;
