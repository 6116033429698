import { connect } from "http2";
import { getDefaultCompilerOptions } from "typescript";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

const bindPart = "bindPart";
const unbind = "unbind";

const hideModestiesRules = [
  {
    type: "hide",
    node: ["modesty_wood_left", "modesty_perforated_left"],
  },
];
export const ConfData3 = Object.freeze([
  //basically for linear workstations
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "WorkStation",
        required: false,
        options: [
          {
            name: "Six Seater",
            size: 6,
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/120/6.png",
            Category: "120 Degree Workstation",
            productId: "6667024e88ddfdd985d91392",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            enableOptions: [],
            disableFeatures: [
              "Aluminium Panel Options",
              "Fabric Panel Accessories",
              "Eco Size Options",
              "Side Radius Wire Management's Dimensions",
              "Plain Wire Management's Dimensions",
              "Plain Raceway Options",
              "Side Radius Raceway Options",
            ],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "Three Seater",
            size: 3,
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/120/3.png",
            productId: "667169d88375d96e7a64ee79",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            Category: "120 Degree Workstation",
            enableOptions: [],
            disableFeatures: [
              "Aluminium Panel Options",
              "Fabric Panel Accessories",
              "Eco Size Options",
              "Side Radius Wire Management's Dimensions",
              "Plain Wire Management's Dimensions",
              "Plain Raceway Options",
              "Side Radius Raceway Options",
            ],
            tableTop: [],
            intermediateLegs: [],
          },
        ],
      },
    ],
  },
  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "WorkStation",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              // {
              //   name: "1350 mm",
              //   value: 1350,
              // },
              // {
              //   name: "1500 mm",
              //   value: 1500,
              // },
            ],
          },
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "700 mm",
                value: 700,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
          // {
          //   name: "Height",
          //   required: false,
          //   dropDown: true,
          //   options: [
          //     {
          //       name: "750 mm",
          //       value: 750,
          //     },
          //     {
          //       name: "1050 mm",
          //       value: 1050,
          //     },
          //     {
          //       name: "1200 mm",
          //       value: 1200,
          //     },
          //   ],
          // },
        ],
      },
      {
        name: "WorkStation",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
            ],
          },
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "700 mm",
                value: 700,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
          // {
          //   name: "Height",
          //   required: false,
          //   dropDown: true,
          //   options: [
          //     {
          //       name: "750 mm",
          //       value: 750,
          //     },
          //     {
          //       name: "1050 mm",
          //       value: 1050,
          //     },
          //     {
          //       name: "1200 mm",
          //       value: 1200,
          //     },
          //   ],
          // },
        ],
      },
    ],
  },

  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "WorkStation",
        required: false,
        options: [
          {
            name: "Legs",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                // enableFeatures: ["Color Options"],
                enableFeatures: ["Eco Size Options"],
                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/74c21dc3-0dc6-49ce-badb-2fc976e1bade/5_6_2024/1720191470521/ecoleft.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Six Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/cb626dd8-fbc4-48ac-b225-cc2c316d442f/5_6_2024/1720191030569/ecoright.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Three Seater"],
                      },
                    ],
                    pid: "https://content.helloviewer.io/uploads/4a9b06a9-050a-4edd-8557-d2f00204bbdd/8_6_2024/1720423029123/ecoright.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: ["FlipUp@work"],
                  },
                  ...hideModestiesRules,
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "connecter_scope",
                      "connecter_io",
                    ],
                  },
                ],
              },
              {
                name: "NOVA",
                thumbnail: "assets/system/thumbnails/Parts/nova.png",
                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                disableFeatures: ["Eco Size Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/d153665b-036a-43a0-8ba7-1d563d1180a5/5_6_2024/1720192947202/noahleft.glb", //"6638e25215077125003fdfc9",//
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Three Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/52bc2285-5680-47d1-9e71-2d7ab8c18664/8_6_2024/1720423386133/noahright.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Six Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/822b549a-5819-4f43-8cf1-78ed6abd7b44/5_6_2024/1720193236797/noahright.glb", //"6638ca9ccb40de82b2da90bb",//
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "FlipUp@work",
                      "connecter_scope",
                      "connecter_io",
                    ],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "@work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                // enableFeatures: ["Color Options"],
                disableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                disableFeatures: ["Eco Size Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/d8cc203d-7413-481c-a197-631f04d53825/5_6_2024/1720186975848/-workleft.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Six Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/4345bcb9-8edc-482e-8f44-347bd4e3357f/5_6_2024/1720186926466/-workright.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Three Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/0d06ef9d-e63c-4784-bbf5-c532c4eb1c6b/8_6_2024/1720424582518/-workright.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                      "connecter_scope",
                      "connecter_io",
                    ],
                  },
                  {
                    type: "show",
                    node: ["workconnect"],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "IO",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                // enableFeatures: ["Color Options"],
                disableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                disableFeatures: ["Eco Size Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/55fe7616-4b78-44e0-8923-bdb1e76cb8aa/5_6_2024/1720188472009/ioleft.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Six Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/abb7397d-e553-4c2a-bb0c-618caa669256/5_6_2024/1720188372813/ioright.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Three Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/ea8919b2-fa1f-489b-aa5e-9bdc4ec5ac47/8_6_2024/1720424220819/ioright.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                      "connecter_scope",
                      "workconnect",
                    ],
                  },
                  {
                    type: "show",
                    node: ["connecter_io"],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                disableFeatures: ["Eco Size Options"],
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/470bcc52-3a5b-48cf-b242-d23855bfaa79/5_6_2024/1720192257313/scopeleft.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Six Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/ca0d5daf-2cfc-43af-a5cd-7426e01c5f72/5_6_2024/1720192230652/scoperight.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Three Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/87a7ef84-9ba1-4d98-9dbf-19092763d52d/8_6_2024/1720423737618/scoperight.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "show",
                    node: ["connecter_scope"],
                  },
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "FlipUp@work",
                      "connecter",
                      "connecter_io",
                    ],
                  },
                  ...hideModestiesRules,
                ],
              },
            ],
          },
          {
            name: "Eco Size Options",
            required: false,
            options: [
              {
                name: "40 mm",
              },
              {
                name: "50 mm",
              },
            ],
          },
        ],
      },
      {
        name: "NON WorkStation",
        required: false,
        options: [
          {
            name: "Legs",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                // enableFeatures: ["Color Options"],
                enableFeatures: ["Eco Size Options"],
                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/74c21dc3-0dc6-49ce-badb-2fc976e1bade/5_6_2024/1720191470521/ecoleft.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Six Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/cb626dd8-fbc4-48ac-b225-cc2c316d442f/5_6_2024/1720191030569/ecoright.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Three Seater"],
                      },
                    ],
                    pid: "https://content.helloviewer.io/uploads/4a9b06a9-050a-4edd-8557-d2f00204bbdd/8_6_2024/1720423029123/ecoright.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: ["FlipUp@work"],
                  },
                  ...hideModestiesRules,
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "connecter_scope",
                    ],
                  },
                ],
              },
              {
                name: "Noah",
                thumbnail: "assets/system/thumbnails/Parts/nova.png",
                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                disableFeatures: ["Eco Size Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/d153665b-036a-43a0-8ba7-1d563d1180a5/5_6_2024/1720192947202/noahleft.glb", //"6638e25215077125003fdfc9",//
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Three Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/52bc2285-5680-47d1-9e71-2d7ab8c18664/8_6_2024/1720423386133/noahright.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Six Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/822b549a-5819-4f43-8cf1-78ed6abd7b44/5_6_2024/1720193236797/noahright.glb", //"6638ca9ccb40de82b2da90bb",//
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "FlipUp@work",
                      "connecter_scope",
                    ],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "@work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                // enableFeatures: ["Color Options"],
                disableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                disableFeatures: ["Eco Size Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/d8cc203d-7413-481c-a197-631f04d53825/5_6_2024/1720186975848/-workleft.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Six Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/4345bcb9-8edc-482e-8f44-347bd4e3357f/5_6_2024/1720186926466/-workright.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Three Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/0d06ef9d-e63c-4784-bbf5-c532c4eb1c6b/8_6_2024/1720424582518/-workright.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                      "connecter_scope",
                    ],
                  },
                  {
                    type: "show",
                    node: ["workconnect"],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "IO",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                // enableFeatures: ["Color Options"],
                disableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                disableFeatures: ["Eco Size Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/55fe7616-4b78-44e0-8923-bdb1e76cb8aa/5_6_2024/1720188472009/ioleft.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Six Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/abb7397d-e553-4c2a-bb0c-618caa669256/5_6_2024/1720188372813/ioright.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Three Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/ea8919b2-fa1f-489b-aa5e-9bdc4ec5ac47/8_6_2024/1720424220819/ioright.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                      "connecter_scope",
                    ],
                  },
                  {
                    type: "show",
                    node: ["workconnect"],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                disableFeatures: ["Eco Size Options"],
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/470bcc52-3a5b-48cf-b242-d23855bfaa79/5_6_2024/1720192257313/scopeleft.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Six Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/ca0d5daf-2cfc-43af-a5cd-7426e01c5f72/5_6_2024/1720192230652/scoperight.glb",
                  },
                  {
                    type: "replacePart",
                    condition: [
                      {
                        feature: "WorkStation",
                        values: ["Three Seater"],
                      },
                    ],
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/87a7ef84-9ba1-4d98-9dbf-19092763d52d/8_6_2024/1720423737618/scoperight.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "show",
                    node: ["connecter_scope"],
                  },
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "FlipUp@work",
                      "connecter",
                    ],
                  },
                  ...hideModestiesRules,
                ],
              },
            ],
          },
          {
            name: "Eco Size Options",
            required: false,
            options: [
              {
                name: "40 mm",
              },
              {
                name: "50 mm",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Table Top",
    multiSelect: false,
    options: [
      {
        name: "WorkStation",
        required: false,
        options: [
          {
            name: "Table Top Options",
            required: false,
            options: [
              {
                name: "Plain",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Side Radius Raceway Options",
                  "Side Radius Wire Management's Dimensions",
                ],
                enableFeatures: [
                  "Plain Raceway Options",
                  "Plain Wire Management's Dimensions",
                ],
                disableOptions:[
                  "300 x 145mm (GV)","400 x 145mm (GV)","450 x 145mm (GV)", "500 x 145mm (GV)","300 x 145mm (GF)", "400 x 145mm (GF)","450 x 145mm (GF)","500 x 145mm (GF)",
                  "300 x 145mm (F)","400 x 145mm (F)","450 x 145mm (F)","500 x 145mm (F)"
                ],
                rules: [
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_waterfall", "Table_topradious"],
                  },
                  {
                    type: hide,
                    node: [
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment@work",
                      "mid_leg_gorment",
                      "gorment2@work",
                      "Table_top_waterfall",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment@work_waterfall",
                      "mid_leg_gorment_waterfall",
                      "gorment2@work_waterfall",
                      "Table_topradious",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment@work_radious",
                      "mid_leg_gorment_radious",
                      "gorment2@work_radious",
                      "FlipUp_radious",
                      "FlipUp",
                    ],
                  },
                ],
              },

              {
                name: "Side radius",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",

                enableFeatures: [
                  "Side Radius Raceway Options",
                  "Side Radius Wire Management's Dimensions",
                ],

                disableFeatures: [
                  "Plain Raceway Options",
                  "Plain Wire Management's Dimensions",
                ],
                disableOptions:[
                  "300 x 145mm (GV)","400 x 145mm (GV)","450 x 145mm (GV)", "500 x 145mm (GV)","300 x 145mm (GF)", "400 x 145mm (GF)","450 x 145mm (GF)","500 x 145mm (GF)",
                  "300 x 145mm (F)","400 x 145mm (F)","450 x 145mm (F)","500 x 145mm (F)"
                ],
                rules: [
                  {
                    type: show,
                    node: ["Table_topradious"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_waterfall", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment@work",
                      "mid_leg_gorment",
                      "gorment2@work",
                      "Table_top_waterfall",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment@work_waterfall",
                      "mid_leg_gorment_waterfall",
                      "gorment2@work_waterfall",

                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment@work_radious",
                      "mid_leg_gorment_radious",
                      "gorment2@work_radious",
                      "FlipUp_radious",
                      "FlipUp",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    default: "FlipUp",
    disableFeatures: ["Plain Wire Management's Dimensions", "Side Radius Wire Management's Dimensions"],

    options: [
      {
        name: "WorkStation",
        required: false,

        options: [
          {
            name: "Plain Raceway Options",
            required: false,

            thumbnail: "/assets/components/ColorsSharing.png",

            rules: [
              {
                type: hide,
                node: ["FlipUp", "gorment", "Raceway"],
              },
            ],
            options: [
              // {
              //   name: "None",
              //   thumbnail: "/assets/components/none.jpg",
              //   // options: [...boxraceway],
              //   // enableFeatures: ["Main Modesty"],
              //   rules: [
              //     {
              //       type: hide,
              //       node: [
              //         "Flip Up",
              //         "Raceway",
              //         "gorment2",
              //         "gorment2@work",
              //         "gorment@work",
              //         "FlipUp@work",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["Table_top"],
              //     },
              //   ],
              // },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                default: true,
                enableOptions: ["Mid Prelam", "Mid Metal","300 x 145mm (F)","400 x 145mm (F)","450 x 145mm (F)","500 x 145mm (F)"],
                disableOptions : ["300 x 145mm (GV)","400 x 145mm (GV)","450 x 145mm (GV)", "500 x 145mm (GV)","300 x 145mm (GF)", "400 x 145mm (GF)","450 x 145mm (GF)","500 x 145mm (GF)",],
                rules: [
                  {
                    type: show,

                    node: ["FlipUp", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,

                    node: [
                      "Table_top",
                      "gorment2",
                      "gorment",
                      "gorment@work",
                      "mid_leg_gorment",
                      "gorment2@work",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Eco", "Noah", "Scope"],
                      },
                    ],
                    node: [
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "gorment@work",
                      "gorment2@work",
                    ],
                  },

                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp", "gorment2@work", "connecter"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp@work"],
                  },
                ],
              },

              {
                name: "Gromet with vertical raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                disableOptions: ["Mid Prelam", "Mid Metal","300 x 145mm (F)","400 x 145mm (F)","450 x 145mm (F)","500 x 145mm (F)","300 x 145mm (GF)", "400 x 145mm (GF)","450 x 145mm (GF)","500 x 145mm (GF)",],
                enableOptions : ["300 x 145mm (GV)","400 x 145mm (GV)","450 x 145mm (GV)", "500 x 145mm (GV)",],
                
                rules: [
                  {
                    type: hide,
                    node: ["FlipUp", "Raceway", "gorment2", "FlipUp@work"],
                  },
                  {
                    type: show,
                    node: ["gorment", "Table_top", "mid_leg_gorment"],
                  },

                  {
                    type: hide,
                    node: [
                      "prelammid",
                      "prelammid@work",
                      "metalmodestymid",
                      "metalmodestymid@work",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment", "gorment2@work"],
                  },
                  {
                    type: applyMaterial,
                    node: ["GORMET"],
                    condition: [
                      {
                        feature: "Laminate",
                        values: [
                          "Mangfall Beech",
                          "Acacia",
                          "Urban Teak",
                          "SILVER GREY DIFFUSE",
                          "Cairo Walnut BaseColor",
                          "Maldau Acacia BaseColor",
                          "plain DefaultMaterial BaseColor",
                          "Thansau Maple BaseColor",
                        ],
                      },
                    ],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
                  },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment@work"],
                  },
                ],
              },
              {
                name: "Gromet With faceplate Cutting",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
           
                enableOptions: ["Mid Prelam", "Mid Metal","300 x 145mm (GF)", "400 x 145mm (GF)","450 x 145mm (GF)","500 x 145mm (GF)",],
                disableOptions : ["300 x 145mm (GV)","400 x 145mm (GV)","450 x 145mm (GV)", "500 x 145mm (GV)","300 x 145mm (F)","400 x 145mm (F)","450 x 145mm (F)","500 x 145mm (F)",],
                
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "FlipUp@work",
                      "gorment@work",
                      "mid_leg_gorment",
                    ],
                  },
                  {
                    type: show,
                    node: ["gorment2", "connecter", "Table_top"],
                  },
                  {
                    type: show,
                    node: ["mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: [
                      "gorment2",
                      "Flipup@work",
                      "gorment@work",
                      "connecter",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment2@work"],
                  },
                ],
              },
            ],
          },
          {
            name: "Plain Wire Management's Dimensions",
            multiSelect: true,
            options: [
              {
                name: "300 x 145mm (F)",
                required: false,
              },
              // {
              //   name: "400 x 145mm (F)",
              //   required: false,
              // },
              {
                name: "450 x 145mm (F)",
                required: false,
              },
              {
                name: "500 x 145mm (F)",
                required: false,
              },
              // {
              //   name: "300 x 145mm (GV)",
              //   required: false,
              // },
              // {
              //   name: "400 x 145mm (GV)",
              //   required: false,
              // },
              // {
              //   name: "450 x 145mm (GV)",
              //   required: false,
              // },
              // {
              //   name: "500 x 145mm (GV)",
              //   required: false,
              // },
              // {
              //   name: "300 x 145mm (GF)",
              //   required: false,
              // },
              // // {
              // //   name: "400 x 145mm (GF)",
              // //   required: false,
              // // },
              // {
              //   name: "450 x 145mm (GF)",
              //   required: false,
              // },
              // {
              //   name: "500 x 145mm (GF)",
              //   required: false,
              // },
            ],
          },
          {
            name: "Side Radius Raceway Options",
            required: false,

            thumbnail: "/assets/components/ColorsSharing.png",

            rules: [
              {
                type: hide,
                node: ["FlipUp", "gorment", "Raceway"],
              },
            ],
            options: [
              // {
              //   name: "None",
              //   thumbnail: "/assets/components/none.jpg",
              //   // options: [...boxraceway],
              //   // enableFeatures: ["Main Modesty"],
              //   rules: [
              //     {
              //       type: hide,
              //       node: [
              //         "Flip Up",
              //         "Raceway",
              //         "gorment2",
              //         "gorment2@work",
              //         "gorment@work",
              //         "FlipUp@work",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["Table_top"],
              //     },
              //   ],
              // },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                default: true,
                enableOptions: ["Mid Prelam", "Mid Metal","300 x 145mm (F)","400 x 145mm (F)","450 x 145mm (F)","500 x 145mm (F)"],
                disableOptions : ["300 x 145mm (GV)","400 x 145mm (GV)","450 x 145mm (GV)", "500 x 145mm (GV)","300 x 145mm (GF)", "400 x 145mm (GF)","450 x 145mm (GF)","500 x 145mm (GF)",],
                
                rules: [
                  {
                    type: show,

                    node: ["FlipUp_radious", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,

                    node: [
                      "Table_top",
                      "gorment2",
                      "gorment",
                      "gorment@work",
                      "mid_leg_gorment",
                      "gorment2@work",
                      "FlipUp",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Eco", "Noah", "Scope"],
                      },
                    ],
                    node: [
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "gorment@work",
                      "gorment2@work",
                    ],
                  },

                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp_radious", "gorment2@work", "connecter"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["FlipUp@work_radious"],
                  },
                ],
              },

              {
                name: "Gromet with vertical raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

               
                disableOptions: ["Mid Prelam", "Mid Metal","300 x 145mm (F)","400 x 145mm (F)","450 x 145mm (F)","500 x 145mm (F)","300 x 145mm (GF)", "400 x 145mm (GF)","450 x 145mm (GF)","500 x 145mm (GF)",],
                enableOptions : ["300 x 145mm (GV)","400 x 145mm (GV)","450 x 145mm (GV)", "500 x 145mm (GV)",],
                
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "FlipUp_radious",
                      "FlipUp@work_radious",
                      "Raceway",
                      "gorment2",
                      "FlipUp@work",
                    ],
                  },
                  {
                    type: show,
                    node: ["gorment", "Table_top", "mid_leg_gorment"],
                  },

                  {
                    type: hide,
                    node: [
                      "prelammid",
                      "prelammid@work",
                      "metalmodestymid",
                      "metalmodestymid@work",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment", "gorment2@work"],
                  },
                  {
                    type: applyMaterial,
                    node: ["GORMET"],
                    condition: [
                      {
                        feature: "Laminate",
                        values: [
                          "Mangfall Beech",
                          "Acacia",
                          "Urban Teak",
                          "SILVER GREY DIFFUSE",
                          "Cairo Walnut BaseColor",
                          "Maldau Acacia BaseColor",
                          "plain DefaultMaterial BaseColor",
                          "Thansau Maple BaseColor",
                        ],
                      },
                    ],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
                  },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment@work"],
                  },
                ],
              },
              {
                name: "Gromet With faceplate Cutting",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
               
                enableOptions: ["Mid Prelam", "Mid Metal","300 x 145mm (GF)", "400 x 145mm (GF)","450 x 145mm (GF)","500 x 145mm (GF)",],
                disableOptions : ["300 x 145mm (GV)","400 x 145mm (GV)","450 x 145mm (GV)", "500 x 145mm (GV)","300 x 145mm (F)","400 x 145mm (F)","450 x 145mm (F)","500 x 145mm (F)",],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "FlipUp@work",
                      "gorment@work",
                      "mid_leg_gorment",
                    ],
                  },
                  {
                    type: show,
                    node: ["gorment2", "connecter", "Table_top"],
                  },
                  {
                    type: show,
                    node: ["mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: [
                      "gorment2",
                      "Flipup@work",
                      "gorment@work",
                      "connecter",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["gorment2@work"],
                  },
                ],
              },
            ],
          },
          {
            name: "Side Radius Wire Management's Dimensions",
            multiSelect: true,
            options: [
              
              {
                name: "300 x 145mm (F)",
                required: false,
              },
              // {
              //   name: "400 x 145mm (F)",
              //   required: false,
              // },
              {
                name: "450 x 145mm (F)",
                required: false,
              },
              {
                name: "500 x 145mm (F)",
                required: false,
              },
              // {
              //   name: "300 x 145mm (GV)",
              //   required: false,
              // },
              // {
              //   name: "400 x 145mm (GV)",
              //   required: false,
              // },
              // {
              //   name: "450 x 145mm (GV)",
              //   required: false,
              // },
              // {
              //   name: "500 x 145mm (GV)",
              //   required: false,
              // },
              // {
              //   name: "300 x 145mm (GF)",
              //   required: false,
              // },
              // {
              //   name: "400 x 145mm (GF)",
              //   required: false,
              // },
              // {
              //   name: "450 x 145mm (GF)",
              //   required: false,
              // },
              // {
              //   name: "500 x 145mm (GF)",
              //   required: false,
              // },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "WorkStation",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON WorkStation",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "WorkStation",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "NON WorkStation",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Privacy",
    multiSelect: false,
    disableFeatures: ["Aluminium Panel Options", "Fabric Panel Accessories"],
    optional: false,
    options: [
      {
        name: "WorkStation",
        required: false,
        disableFeatures: [
          "Aluminium Panel Options",
          "Fabric Panel Accessories",
        ],
        options: [
          {
            name: "Side Modesty",
            nodeName: [
              "prelamleft",
              "metalmodestyleft",
              "prelamright",
              "metalmodestyright",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "Prelam",
                thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
                rules: [
                  {
                    type: "show",
                    node: ["prelamleft", "prelamright"],
                  },
                  {
                    type: "hide",
                    node: ["metalmodestyleft", "metalmodestyright"],
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",
                    node: ["metalmodestyleft", "metalmodestyright"],
                  },
                  {
                    type: "hide",
                    node: ["prelamleft", "prelamright"],
                  },
                ],
              },
            ],
          },
          {
            name: "Mid Modesty",
            nodeName: [
              "prelammid",
              "metalmodestymid",
              "metalmodestymid@work",
              "prelammid@work",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelammid",
                      "metalmodestymid",
                      "metalmodestymid@work",
                      "prelammid@work",
                    ],
                  },
                ],
              },
              {
                name: "Mid Prelam",
                thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
                rules: [
                  {
                    type: "show",
                    node: ["prelammid"],
                  },
                  {
                    type: "hide",
                    node: ["metalmodestymid", "gorment"],
                  },
                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["prelammid", "metalmodestymid@work"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["prelammid@work"],
                  },
                ],
              },
              {
                name: "Mid Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",
                    node: ["metalmodestymid"],
                  },
                  {
                    type: "hide",
                    node: ["prelammid", "gorment"],
                  },
                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["prelammid@work", "metalmodestymid"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["metalmodestymid@work"],
                  },
                ],
              },
            ],
          },
          {
            name: "Privacy Panels",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                  "Privacy Panel Height",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "Alluminiumname_board",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "aluminium",
                      "alumfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                disableOptions: ["300G", "300A", "450G", "450A"],
                enableOptions: ["300F", "450F"],
                disableFeatures: [
                  "Aluminium Panel Options",
                  "End to End Panel Options",
                ],
                enableFeatures: [
                  "Fabric Panel Accessories",
                  "Privacy Panel Height",
                ],

                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["fabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              {
                name: "Glass",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: ["300F", "300A", "450F", "450A"],
                enableOptions: ["300G", "450G"],
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                ],
                enableFeatures: ["Privacy Panel Height"],
                rules: [
                  {
                    type: "show",
                    node: ["glass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                disableOptions: ["300F", "300G", "450F", "450G"],
                enableOptions: ["300A", "450A"],
                //pid: "6122f35afe408b1279847325",
                enableFeatures: ["Privacy Panel Height"],
                disableFeatures: [
                  "Fabric Panel Accessories",
                  "End to End Panel Options",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["aluminium", "alumfullfabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              // {
              //   name: "end to end panels",
              //   thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableFeatures: ["End to End Panel Options (S)"],
              //   disableFeatures: [
              //     "Fabric Panel Accessories (S)",
              //     "Aluminium Panel Options (S)",
              //   ],
              //   rules: [
              //     {
              //       type: replacePart,
              //       node: [
              //         "End1",
              //         "End2",
              //         "End3",
              //         "End4",
              //         "End5",
              //         "End6",
              //         "End7",
              //         "End8",
              //         "End9",
              //         "End10",
              //         "End11",
              //         "End12",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/68f3c295-7cee-4c0a-93ca-bd71fc652169/4_5_2024/1717499694262/end-to-end-panels-.glb",
              //     },
              //     {
              //       type: replacePart,
              //       condition: [
              //         {
              //           feature: "SHARING",
              //           values: ["Two Seater"],
              //         },
              //       ],
              //       node: [
              //         "End1",
              //         "End2",
              //         "End3",
              //         "End4",
              //         "End5",
              //         "End6",
              //         "End7",
              //         "End8",
              //         "End9",
              //         "End10",
              //         "End11",
              //         "End12",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/13e0c646-8b9c-4974-82a8-2f19c1fce3fc/24_5_2024/1719233596450/2seaterendtoendpane-.glb",
              //     },
              //     {
              //       type: hide,
              //       node: [
              //         "aluminium",
              //         "alumfullfabric",
              //         "fabric",
              //         "alumfullfabric",
              //         "glass",
              //         "alumglass",
              //         "fabric",
              //         "aluminium",
              //         "alumfullfabric",
              //         "alumfulllaminate",
              //         "alumhalfandhalf",
              //         "alumglass",
              //         "endfulllaminate008",
              //         "endglass008",
              //         "endhalfandhalf008",
              //         "endfulllaminate011",
              //         "endglassendhalfandhalf011",
              //         "endglass011",
              //         "endhalfandhalf011",
              //       ],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Privacy Panel Height",
            multiSelect: true,
            options: [
              {
                name: "300F",
                required: false,
              },

              {
                name: "450F",
                required: false,
              },
              {
                name: "300G",
                required: false,
              },

              {
                name: "450G",
                required: false,
              },
              {
                name: "300A",
                enableFeatures: ["Aluminium Panel Options"],
                enableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                disableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                required: false,
              },

              {
                name: "450A",
                disableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                enableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                enableFeatures: ["Aluminium Panel Options"],
                required: false,
              },
            ],
          },
          // {
          //   name: "Return Panels",
          //   nodeName: ["fabric", "glass", "aluminium"],
          //   required: false,
          //   options: [
          //     {
          //       name: "None",
          //       thumbnail: "/assets/components/none.jpg",
          //       pid: "",
          //       disableFeatures: [
          //         "Aluminium Panel Options",
          //         "Fabric Panel Accessories",
          //       ],
          //       rules: [
          //         {
          //           type: "hide",
          //           node: [
          //             "fabric",
          //             "glass",
          //             "aluminium",
          //             "alumfabric",
          //             "alumfullfabric",
          //             "alumfulllaminate",
          //             "alumhalfandhalf",
          //             "hangingwhiteboard",
          //             "metal_nameplate",
          //             "Alluminiumname_board",
          //             "rail",
          //             "mid1",
          //             "mid2",
          //             "mid3",
          //             "mid4",
          //             "mid5",
          //             "mid6",
          //             "mid7",
          //             "mid8",
          //             "mid9",
          //             "mid10",
          //             "mid11",
          //             "mid12",
          //             "mid13",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Fabric",
          //       thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
          //       //pid: "6122f35afe408b1279847325",

          //       disableOptions: [""],

          //       rules: [
          //         {
          //           type: replacePart,
          //           node: [
          //             "mid1",
          //             "mid2",
          //             "mid3",
          //             "mid4",
          //             "mid5",
          //             "mid6",
          //             "mid7",
          //             "mid8",
          //             "mid9",
          //             "mid10",
          //             "mid11",
          //             "mid12",
          //             "mid13",
          //           ],

          //           pid: "https://content.helloviewer.io/uploads/59b08091-72f7-40a7-afc9-6dc07897f20f/4_5_2024/1717493219410/returnfabricscreen.glb",
          //         },
          //         // {
          //         //   type: "hide",
          //         //   node: [
          //         //     "glass",
          //         //     "aluminium",
          //         //     "alumfabric",
          //         //     "alumfullfabric",
          //         //     "alumfulllaminate",
          //         //     "alumhalfandhalf",
          //         //     "hangingwhiteboard",
          //         //     "metal_nameplate",
          //         //     "Alluminiumname_board",
          //         //     "rail",
          //         //   ],
          //         // },
          //       ],
          //       // enableOptions: ['ColorOptionsPrivacy'],
          //     },
          //     {
          //       name: "Glass",
          //       thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
          //       //pid: "6122f35afe408b1279847325",
          //       disableFeatures: [
          //         "Aluminium Panel Options",
          //         "Fabric Panel Accessories",
          //       ],
          //       rules: [
          //         {
          //           type: replacePart,
          //           node: [
          //             "mid1",
          //             "mid2",
          //             "mid3",
          //             "mid4",
          //             "mid5",
          //             "mid6",
          //             "mid7",
          //             "mid8",
          //             "mid9",
          //             "mid10",
          //             "mid11",
          //             "mid12",
          //             "mid13",
          //           ],

          //           pid: "https://content.helloviewer.io/uploads/41dbd369-2caa-41df-a3bd-3d18a0e7dffc/5_5_2024/1717567546607/glass-panels-mid-.glb",
          //         },
          //       ],
          //     },
          //     // {
          //     //   name: "Aluminium",
          //     //   thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
          //     //   //pid: "6122f35afe408b1279847325",
          //     //   enableFeatures: ["Aluminium Panel Options"],
          //     //   disableFeatures: ["Fabric Panel Accessories"],
          //     //   rules: [
          //     //     {
          //     //       type: "show",
          //     //       node: ["aluminium", "alumfullfabric"],
          //     //     },
          //     //     {
          //     //       type: "hide",
          //     //       node: ["fabric", "glass"],
          //     //     },
          //     //   ],
          //     // },
          //   ],
          // },
          {
            name: "Aluminium Panel Options",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "Full Fabric3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
              {
                name: "Full Fabric4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
            ],
          },

          {
            name: "Fabric Panel Accessories",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                    ],
                  },
                ],
              },
              {
                name: "Accessories Rail",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["rail"],
                  },
                ],
              },
              {
                name: "Metal Nameplate",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["metal_nameplate"],
                  },
                  {
                    type: "hide",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["Alluminiumname_board"],
                  },
                ],
              },
              {
                name: "Alluminiumname_board",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["Alluminiumname_board"],
                  },
                  {
                    type: "hide",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["metal_nameplate"],
                  },
                ],
              },
              {
                name: "Hanging whiteboard",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels", values: ["Fabric"] },
                    ],
                    node: ["hangingwhiteboard"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON WorkStation",
        required: false,
        options: [
          {
            name: "Side Modesty",
            nodeName: [
              "prelamleft",
              "metalmodestyleft",
              "prelamright",
              "metalmodestyright",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "Prelam",
                thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
                rules: [
                  {
                    type: "show",
                    node: ["prelamleft", "prelamright"],
                  },
                  {
                    type: "hide",
                    node: ["metalmodestyleft", "metalmodestyright"],
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",
                    node: ["metalmodestyleft", "metalmodestyright"],
                  },
                  {
                    type: "hide",
                    node: ["prelamleft", "prelamright"],
                  },
                ],
              },
            ],
          },
          {
            name: "Mid Modesty",
            nodeName: [
              "prelammid",
              "metalmodestymid",
              "metalmodestymid@work",
              "prelammid@work",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelammid",
                      "metalmodestymid",
                      "metalmodestymid@work",
                      "prelammid@work",
                    ],
                  },
                ],
              },
              {
                name: "Mid Prelam",
                thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
                rules: [
                  {
                    type: "show",
                    node: ["prelammid"],
                  },
                  {
                    type: "hide",
                    node: ["metalmodestymid", "gorment"],
                  },
                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["prelammid", "metalmodestymid@work"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["prelammid@work"],
                  },
                ],
              },
              {
                name: "Mid Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",
                    node: ["metalmodestymid"],
                  },
                  {
                    type: "hide",
                    node: ["prelammid", "gorment"],
                  },
                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["prelammid@work", "metalmodestymid"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work", "IO"],
                      },
                    ],
                    node: ["metalmodestymid@work"],
                  },
                ],
              },
            ],
          },

          {
            name: "Privacy Panels",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "Alluminiumname_board",
                      "hangingwhiteboard",

                      "metal_nameplate",
                      "aluminium",
                      "alumfabric",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",

                      "rail",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                disableOptions: [""],
                disableFeatures: ["Aluminium Panel Options"],
                enableFeatures: ["Fabric Panel Accessories"],

                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["fabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "End1",
                      "alumglass",
                      "glass",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              {
                name: "Glass",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Aluminium Panel Options",
                  "Fabric Panel Accessories",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["glass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                enableFeatures: ["Aluminium Panel Options"],
                disableFeatures: ["Fabric Panel Accessories"],
                rules: [
                  {
                    type: "show",
                    node: ["aluminium", "alumfullfabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              // {
              //   name: "end to end panels",
              //   thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableFeatures: ["Aluminium Panel Options"],
              //   disableFeatures: ["Fabric Panel Accessories"],
              //   rules: [
              //     {
              //       type: replacePart,
              //       node: [
              //         "End1",
              //         "End2",
              //         "End3",
              //         "End4",
              //         "End5",
              //         "End6",
              //         "End7",
              //         "End8",
              //         "End9",
              //         "End10",
              //         "End11",
              //         "End12",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/68f3c295-7cee-4c0a-93ca-bd71fc652169/4_5_2024/1717499694262/end-to-end-panels-.glb",
              //     },
              //     {
              //       type: hide,
              //       node: ["fabric", "glass", "alumglass"],
              //     },
              //   ],
              // },
            ],
          },
          // {
          //   name: "Return Panels",
          //   nodeName: ["fabric", "glass", "aluminium"],
          //   required: false,
          //   options: [
          //     {
          //       name: "None",
          //       thumbnail: "/assets/components/none.jpg",
          //       pid: "",
          //       disableFeatures: [
          //         "Aluminium Panel Options",
          //         "Fabric Panel Accessories",
          //       ],
          //       rules: [
          //         {
          //           type: "hide",
          //           node: [
          //             "fabric",
          //             "glass",
          //             "aluminium",
          //             "alumfabric",
          //             "alumfullfabric",
          //             "alumfulllaminate",
          //             "alumhalfandhalf",
          //             "hangingwhiteboard",
          //             "metal_nameplate",
          //             "Alluminiumname_board",
          //             "rail",
          //             "mid1",
          //             "mid2",
          //             "mid3",
          //             "mid4",
          //             "mid5",
          //             "mid6",
          //             "mid7",
          //             "mid8",
          //             "mid9",
          //             "mid10",
          //             "mid11",
          //             "mid12",
          //             "mid13",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Fabric",
          //       thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
          //       //pid: "6122f35afe408b1279847325",

          //       disableOptions: [""],

          //       rules: [
          //         {
          //           type: replacePart,
          //           node: [
          //             "mid1",
          //             "mid2",
          //             "mid3",
          //             "mid4",
          //             "mid5",
          //             "mid6",
          //             "mid7",
          //             "mid8",
          //             "mid9",
          //             "mid10",
          //             "mid11",
          //             "mid12",
          //             "mid13",
          //           ],

          //           pid: "https://content.helloviewer.io/uploads/a30dc460-462f-4ada-8936-9d749ff20fcb/5_5_2024/1717535541370/midfabric.glb",
          //         },
          //         // {
          //         //   type: "hide",
          //         //   node: [
          //         //     "glass",
          //         //     "aluminium",
          //         //     "alumfabric",
          //         //     "alumfullfabric",
          //         //     "alumfulllaminate",
          //         //     "alumhalfandhalf",
          //         //     "hangingwhiteboard",
          //         //     "metal_nameplate",
          //         //     "Alluminiumname_board",
          //         //     "rail",
          //         //   ],
          //         // },
          //       ],
          //       // enableOptions: ['ColorOptionsPrivacy'],
          //     },
          //     {
          //       name: "Glass",
          //       thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
          //       //pid: "6122f35afe408b1279847325",
          //       disableFeatures: [
          //         "Aluminium Panel Options",
          //         "Fabric Panel Accessories",
          //       ],
          //       rules: [
          //         {
          //           type: replacePart,
          //           node: [
          //             "mid1",
          //             "mid2",
          //             "mid3",
          //             "mid4",
          //             "mid5",
          //             "mid6",
          //             "mid7",
          //             "mid8",
          //             "mid9",
          //             "mid10",
          //             "mid11",
          //             "mid12",
          //             "mid13",
          //           ],

          //           pid: "https://content.helloviewer.io/uploads/5f50051b-8bf3-467a-9f1d-1e027143cd83/5_5_2024/1717535616785/midglass.glb",
          //         },
          //       ],
          //     },
          //     // {
          //     //   name: "Aluminium",
          //     //   thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
          //     //   //pid: "6122f35afe408b1279847325",
          //     //   enableFeatures: ["Aluminium Panel Options"],
          //     //   disableFeatures: ["Fabric Panel Accessories"],
          //     //   rules: [
          //     //     {
          //     //       type: "show",
          //     //       node: ["aluminium", "alumfullfabric"],
          //     //     },
          //     //     {
          //     //       type: "hide",
          //     //       node: ["fabric", "glass"],
          //     //     },
          //     //   ],
          //     // },
          //   ],
          // },
          {
            name: "Aluminium Panel Options",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "Full Fabric",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: [
                      "alumfullfabric",
                      "aluminium",
                      "endfullfabric",
                      "endfullfabric.001",
                      "endfullfabric.002",
                      "endfullfabric.003",
                      "endfullfabric.004",
                      "endfullfabric.005",
                      "endfullfabric.006",
                      "endfullfabric.007",
                      "endfullfabric.008",
                      "endfullfabric.009",
                      "endfullfabric.010",
                      "endfullfabric.011",
                    ],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "endfulllaminate",
                      "endglass",
                      "endhalfandhalf",
                      "endfulllaminate.001",
                      "endfulllaminate.002",
                      "endfulllaminate.003",
                      "endfulllaminate.004",
                      "endfulllaminate.005",
                      "endfulllaminate.006",
                      "endfulllaminate.007",
                      "endfulllaminate.008",
                      "endfulllaminate.009",
                      "endfulllaminate.010",
                      "endfulllaminate.011",
                      "endglass.001",
                      "endglass.002",
                      "endglass.003",
                      "endglass.004",
                      "endglass.005",
                      "endglass.006",
                      "endglass.007",
                      "endglass.008",
                      "endglass.009",
                      "endglass.010",
                      "endglass.011",
                      "endhalfandhalf.001",
                      "endhalfandhalf.002",
                      "endhalfandhalf.003",
                      "endhalfandhalf.004",
                      "endhalfandhalf.005",
                      "endhalfandhalf.006",
                      "endhalfandhalf.007",
                      "endhalfandhalf.008",
                      "endhalfandhalf.009",
                      "endhalfandhalf.010",
                      "endhalfandhalf.011",
                    ],
                  },
                ],
              },
              {
                name: "Full Pin up",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: [
                      "alumfullfabric",
                      "aluminium",
                      "endfullfabric",
                      "endfullfabric.001",
                      "endfullfabric.002",
                      "endfullfabric.003",
                      "endfullfabric.004",
                      "endfullfabric.005",
                      "endfullfabric.006",
                      "endfullfabric.007",
                      "endfullfabric.008",
                      "endfullfabric.009",
                      "endfullfabric.010",
                      "endfullfabric.011",
                    ],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "endfulllaminate",
                      "endglass",
                      "endhalfandhalf",
                      "endfulllaminate.001",
                      "endfulllaminate.002",
                      "endfulllaminate.003",
                      "endfulllaminate.004",
                      "endfulllaminate.005",
                      "endfulllaminate.006",
                      "endfulllaminate.007",
                      "endfulllaminate.008",
                      "endfulllaminate.009",
                      "endfulllaminate.010",
                      "endfulllaminate.011",
                      "endglass.001",
                      "endglass.002",
                      "endglass.003",
                      "endglass.004",
                      "endglass.005",
                      "endglass.006",
                      "endglass.007",
                      "endglass.008",
                      "endglass.009",
                      "endglass.010",
                      "endglass.011",
                      "endhalfandhalf.001",
                      "endhalfandhalf.002",
                      "endhalfandhalf.003",
                      "endhalfandhalf.004",
                      "endhalfandhalf.005",
                      "endhalfandhalf.006",
                      "endhalfandhalf.007",
                      "endhalfandhalf.008",
                      "endhalfandhalf.009",
                      "endhalfandhalf.010",
                      "endhalfandhalf.011",
                    ],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: [
                      "alumhalfandhalf",
                      "aluminium",
                      "endhalfandhalf",
                      "endhalfandhalf.001",
                      "endhalfandhalf.002",
                      "endhalfandhalf.003",
                      "endhalfandhalf.004",
                      "endhalfandhalf.005",
                      "endhalfandhalf.006",
                      "endhalfandhalf.007",
                      "endhalfandhalf.008",
                      "endhalfandhalf.009",
                      "endhalfandhalf.010",
                      "endhalfandhalf.011",
                    ],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfulllaminate",
                      "alumfullfabric",
                      "alumglass",
                      "endfulllaminate",
                      "endglass",
                      "endhalfandhalf",
                      "endfulllaminate.001",
                      "endfulllaminate.002",
                      "endfulllaminate.003",
                      "endfulllaminate.004",
                      "endfulllaminate.005",
                      "endfulllaminate.006",
                      "endfulllaminate.007",
                      "endfulllaminate.008",
                      "endfulllaminate.009",
                      "endfulllaminate.010",
                      "endfulllaminate.011",
                      "endglass.001",
                      "endglass.002",
                      "endglass.003",
                      "endglass.004",
                      "endglass.005",
                      "endglass.006",
                      "endglass.007",
                      "endglass.008",
                      "endglass.009",
                      "endglass.010",
                      "endglass.011",
                      "endfullfabric",
                      "endfullfabric.001",
                      "endfullfabric.002",
                      "endfullfabric.003",
                      "endfullfabric.004",
                      "endfullfabric.005",
                      "endfullfabric.006",
                      "endfullfabric.007",
                      "endfullfabric.008",
                      "endfullfabric.009",
                      "endfullfabric.010",
                      "endfullfabric.011",
                    ],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: [
                      "alumhalfandhalf",
                      "aluminium",
                      "endhalfandhalf",
                      "endhalfandhalf.001",
                      "endhalfandhalf.002",
                      "endhalfandhalf.003",
                      "endhalfandhalf.004",
                      "endhalfandhalf.005",
                      "endhalfandhalf.006",
                      "endhalfandhalf.007",
                      "endhalfandhalf.008",
                      "endhalfandhalf.009",
                      "endhalfandhalf.010",
                      "endhalfandhalf.011",
                    ],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfulllaminate",
                      "alumfullfabric",
                      "alumglass",
                      "endfulllaminate",
                      "endglass",
                      "endhalfandhalf",
                      "endfulllaminate.001",
                      "endfulllaminate.002",
                      "endfulllaminate.003",
                      "endfulllaminate.004",
                      "endfulllaminate.005",
                      "endfulllaminate.006",
                      "endfulllaminate.007",
                      "endfulllaminate.008",
                      "endfulllaminate.009",
                      "endfulllaminate.010",
                      "endfulllaminate.011",
                      "endglass.001",
                      "endglass.002",
                      "endglass.003",
                      "endglass.004",
                      "endglass.005",
                      "endglass.006",
                      "endglass.007",
                      "endglass.008",
                      "endglass.009",
                      "endglass.010",
                      "endglass.011",
                      "endfullfabric",
                      "endfullfabric.001",
                      "endfullfabric.002",
                      "endfullfabric.003",
                      "endfullfabric.004",
                      "endfullfabric.005",
                      "endfullfabric.006",
                      "endfullfabric.007",
                      "endfullfabric.008",
                      "endfullfabric.009",
                      "endfullfabric.010",
                      "endfullfabric.011",
                    ],
                  },
                ],
              },
              {
                name: "Full laminate",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: [
                      "alumfulllaminate",
                      "aluminium",
                      "endfulllaminate",
                      "endfulllaminate.001",
                      "endfulllaminate.002",
                      "endfulllaminate.003",
                      "endfulllaminate.004",
                      "endfulllaminate.005",
                      "endfulllaminate.006",
                      "endfulllaminate.007",
                      "endfulllaminate.008",
                      "endfulllaminate.009",
                      "endfulllaminate.010",
                      "endfulllaminate.011",
                    ],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumglass",
                      "endglass",
                      "endglass.001",
                      "endglass.002",
                      "endglass.003",
                      "endglass.004",
                      "endglass.005",
                      "endglass.006",
                      "endglass.007",
                      "endglass.008",
                      "endglass.009",
                      "endglass.010",
                      "endglass.011",
                      "endfullfabric",
                      "endfullfabric.001",
                      "endfullfabric.002",
                      "endfullfabric.003",
                      "endfullfabric.004",
                      "endfullfabric.005",
                      "endfullfabric.006",
                      "endfullfabric.007",
                      "endfullfabric.008",
                      "endfullfabric.009",
                      "endfullfabric.010",
                      "endfullfabric.011",
                      "endhalfandhalf",
                      "endhalfandhalf.001",
                      "endhalfandhalf.002",
                      "endhalfandhalf.003",
                      "endhalfandhalf.004",
                      "endhalfandhalf.005",
                      "endhalfandhalf.006",
                      "endhalfandhalf.007",
                      "endhalfandhalf.008",
                      "endhalfandhalf.009",
                      "endhalfandhalf.010",
                      "endhalfandhalf.011",
                    ],
                  },
                ],
              },
              {
                name: "Full Glass",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    node: [
                      "alumglass",
                      "endglass",
                      "endglass.001",
                      "endglass.002",
                      "endglass.003",
                      "endglass.004",
                      "endglass.005",
                      "endglass.006",
                      "endglass.007",
                      "endglass.008",
                      "endglass.009",
                      "endglass.010",
                      "endglass.011",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "endfullfabric",
                      "endfullfabric.001",
                      "endfullfabric.002",
                      "endfullfabric.003",
                      "endfullfabric.004",
                      "endfullfabric.005",
                      "endfullfabric.006",
                      "endfullfabric.007",
                      "endfullfabric.008",
                      "endfullfabric.009",
                      "endfullfabric.010",
                      "endfullfabric.011",
                      "endhalfandhalf",
                      "endhalfandhalf.001",
                      "endhalfandhalf.002",
                      "endhalfandhalf.003",
                      "endhalfandhalf.004",
                      "endhalfandhalf.005",
                      "endhalfandhalf.006",
                      "endhalfandhalf.007",
                      "endhalfandhalf.008",
                      "endhalfandhalf.009",
                      "endhalfandhalf.010",
                      "endhalfandhalf.011",
                      "endfulllaminate",
                      "endfulllaminate.001",
                      "endfulllaminate.002",
                      "endfulllaminate.003",
                      "endfulllaminate.004",
                      "endfulllaminate.005",
                      "endfulllaminate.006",
                      "endfulllaminate.007",
                      "endfulllaminate.008",
                      "endfulllaminate.009",
                      "endfulllaminate.010",
                      "endfulllaminate.011",
                    ],
                  },
                ],
              },
            ],
          },

          {
            name: "Fabric Panel Accessories",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                    ],
                  },
                ],
              },
              {
                name: "Accessories Rail",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["rail"],
                  },
                ],
              },
              {
                name: "Metal Nameplate",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["metal_nameplate"],
                  },
                  {
                    type: "hide",
                    node: ["Alluminiumname_board"],
                  },
                ],
              },
              {
                name: "Alluminiumname_board",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: ["Alluminiumname_board"],
                  },
                  {
                    type: "hide",
                    node: ["metal_nameplate"],
                  },
                ],
              },
              {
                name: "Hanging whiteboard",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: ["hangingwhiteboard"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Glass Color",
    options: [
      {
        name: "WorkStation",
        required: false,
        options: [
          {
            name: "SKY BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
              },
            ],
          },
          {
            name: "SOLAR YELLOW",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
              },
            ],
          },
          {
            name: "OCEAN GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
              },
            ],
          },
          {
            name: "ORANGE MIST",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
              },
            ],
          },
          {
            name: "FLAME RED",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
              },
            ],
          },
          {
            name: "CASIS PURPLE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
              },
            ],
          },
          {
            name: "AZURE BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
              },
            ],
          },
          {
            name: "APPLE GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
              },
            ],
          },
          {
            name: "SUNNY ORANGE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
              },
            ],
          },
          {
            name: "SUPER WHITE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
              },
            ],
          },
          {
            name: "GREEN LIME",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON WorkStation",
        required: false,
        options: [
          {
            name: "SKY BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
              },
            ],
          },
          {
            name: "SOLAR YELLOW",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
              },
            ],
          },
          {
            name: "OCEAN GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
              },
            ],
          },
          {
            name: "ORANGE MIST",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
              },
            ],
          },
          {
            name: "FLAME RED",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
              },
            ],
          },
          {
            name: "CASIS PURPLE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
              },
            ],
          },
          {
            name: "AZURE BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
              },
            ],
          },
          {
            name: "APPLE GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
              },
            ],
          },
          {
            name: "SUNNY ORANGE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
              },
            ],
          },
          {
            name: "SUPER WHITE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
              },
            ],
          },
          {
            name: "GREEN LIME",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fabric Color",
    options: [
      {
        name: "WorkStation",
        required: false,
        options: [
          {
            name: "Tangerine",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
              },
            ],
          },
          {
            name: "Acid",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
              },
            ],
          },
          {
            name: "Acorn",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
              },
            ],
          },
          {
            name: "Amethyst",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
              },
            ],
          },
          {
            name: "Apollo",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
              },
            ],
          },
          {
            name: "Azure",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
              },
            ],
          },

          {
            name: "Blush",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
              },
            ],
          },
          {
            name: "Brown",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
              },
            ],
          },
          {
            name: "Burnt Orange",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
              },
            ],
          },
          {
            name: "Corn Flower",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
              },
            ],
          },
          {
            name: "Magenta",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
              },
            ],
          },

          {
            name: "Pink",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
              },
            ],
          },
          {
            name: "Purple",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
              },
            ],
          },
          {
            name: "Raspberry",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
              },
            ],
          },
          {
            name: "Ruby",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
              },
            ],
          },
          {
            name: "Salmon",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
              },
            ],
          },
          {
            name: "Shimmer",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
              },
            ],
          },

          {
            name: "Electric",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
              },
            ],
          },
          {
            name: "Frengia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
              },
            ],
          },
          {
            name: "Baby Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
              },
            ],
          },
        ],
      },
      {
        name: "NON WorkStation",
        required: false,
        options: [
          {
            name: "Tangerine",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
              },
            ],
          },
          {
            name: "Acid",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
              },
            ],
          },
          {
            name: "Acorn",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
              },
            ],
          },
          {
            name: "Amethyst",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
              },
            ],
          },
          {
            name: "Apollo",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
              },
            ],
          },
          {
            name: "Azure",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
              },
            ],
          },

          {
            name: "Blush",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
              },
            ],
          },
          {
            name: "Brown",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
              },
            ],
          },
          {
            name: "Burnt Orange",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
              },
            ],
          },
          {
            name: "Corn Flower",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
              },
            ],
          },
          {
            name: "Magenta",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
              },
            ],
          },

          {
            name: "Pink",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
              },
            ],
          },
          {
            name: "Purple",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
              },
            ],
          },
          {
            name: "Raspberry",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
              },
            ],
          },
          {
            name: "Ruby",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
              },
            ],
          },
          {
            name: "Salmon",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
              },
            ],
          },
          {
            name: "Shimmer",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
              },
            ],
          },

          {
            name: "Electric",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
              },
            ],
          },
          {
            name: "Frengia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
              },
            ],
          },
          {
            name: "Baby Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Accessories",

    options: [
      {
        name: "WorkStation",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "Keyboard Trays",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray", "Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard Tray",
                thumbnail: "assets/system/thumbnails/Parts/keyboardtray.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray"],
                  },
                  {
                    type: "show",
                    node: ["Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard and Mouse Tray",
                thumbnail: "assets/system/thumbnails/Parts/keyandmousetray.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key_board_tray"],
                  },
                  {
                    type: "show",
                    node: ["Key&mousetray"],
                  },
                ],
              },
            ],
          },
          {
            name: "CPU Stands",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand", "CPU_stand"],
                  },
                ],
              },
              {
                name: "CPU Stand",
                thumbnail: "assets/system/thumbnails/Parts/cpustand.png",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand"],
                  },
                  {
                    type: "show",
                    node: ["CPU_stand"],
                  },
                ],
              },
              {
                name: "Hanging CPU Stand",
                thumbnail:
                  "assets/system/thumbnails/Parts/hangingcpustand .png",
                rules: [
                  {
                    type: "hide",
                    node: ["CPU_stand"],
                  },
                  {
                    type: "show",
                    node: ["hangingcpustand"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON WorkStation",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "Keyboard Trays",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray", "Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard Tray",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray"],
                  },
                  {
                    type: "show",
                    node: ["Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard and Mouse Tray",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key_board_tray"],
                  },
                  {
                    type: "show",
                    node: ["Key&mousetray"],
                  },
                ],
              },
            ],
          },
          {
            name: "CPU Stands",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand", "CPU_stand"],
                  },
                ],
              },
              {
                name: "CPU Stand",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand"],
                  },
                  {
                    type: "show",
                    node: ["CPU_stand"],
                  },
                ],
              },
              {
                name: "Hanging CPU Stand",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["CPU_stand"],
                  },
                  {
                    type: "show",
                    node: ["hangingcpustand"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;