import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";

export default function Modal({
  children,
  onClose,
  show,
  type,
}: {
  show: boolean;
  children: any;
  onClose: () => void;
  type?: string;
}) {
  useEffect(() => {
    let container: HTMLElement | null = null;
    let root: ReactDOM.Root | null = null;
    let containerName: string | null = null;

    // Determine or create the modal container
    switch (type) {
      case "quote":
        containerName = "QuotationModal"
        break;
      case "pricing":
        containerName = "PricingModal"
        break;
      case "profile":
        containerName = "ProfileModal"
        break;
      default:
        containerName = "Modal"
        break;
    }
    container = document.getElementById(containerName);
    if (!container) {
      container = document.createElement("div");
      container.id = containerName;
      document.body.appendChild(container);
    }

    if (show) {
      root = ReactDOM.createRoot(container);
      root.render(
        <div
          onClick={(e: any) => {
            if (e.currentTarget === e.target) {
              onClose();
            }
          }}
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          style={{ backdropFilter: "blur(3px)"}}
        >
          <div className="bg-white border p-4 pb-4 rounded-md shadow-xl" style={{maxWidth:"95%"}}>
            {children}
          </div>
        </div>
      );
    }

    return () => {
      if (root) root.unmount();
      if (container && container.parentNode) {
        container.parentNode.removeChild(container);
      }
    };
  }, [children, onClose, show, type]);

  return null; // Nothing to render in the main React tree
}
